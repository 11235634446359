import { isSignInWithEmailLink, signInWithEmailLink, User as FirebaseUser } from "firebase/auth";
import { Coffee, Sparkles } from "lucide-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "src/utils/firebaseUtils";
import { createUserInAPI } from "./SignIn";
import { CLIENT_ROUTE } from "src/utils/constants";

function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function verifyEmail() {
      const url = window.location.href;
      console.log("url", url);

      try {
        if (isSignInWithEmailLink(auth, url)) {
          const searchParams = new URLSearchParams(window.location.search);
          const email = searchParams.get("email");
          if (!email) {
            throw new Error("Email not found in URL");
          }

          console.log("email", email);

          const result = await signInWithEmailLink(auth, email, url);
          // Try to create the user in server
          const firebaseUser = result.user;
          const res = await createUserInAPI(firebaseUser, dispatch);

          if (res) {
            console.log("res", res);
            navigate(CLIENT_ROUTE.DOCUMENT_HOME);
          } else {
            throw new Error("Error creating user");
          }
        }
      } catch (error) {
        alert("Error signing in. Please sign in again.");
        navigate(CLIENT_ROUTE.SIGN_IN);
        console.error("Error during email verification", error);
      }
    }

    verifyEmail();
  }, [auth, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen  bg-orange-50">
      <Icon />
      <SignInCard />
    </div>
  );
}

function Icon() {
  return (
    <div className="flex items-center justify-start w-96 mb-4 ">
      <Coffee size={32} />
    </div>
  );
}
// SignInCard component that contains the sign-in form and social buttons
function SignInCard() {
  return (
    <div className="flex flex-col items-center w-96 p-8 border border-orange-100 rounded-lg bg-stone-50 shadow-md">
      <EmailHeader />
      <EmailBody />
    </div>
  );
}

// EmailHeader component for the header text
function EmailHeader() {
  return (
    <div className="flex justify-start w-full mb-2 text-lg font-medium">Sign in with email</div>
  );
}

function EmailBody() {
  return (
    <p className="flex justify-start w-full  text-lg text-gray-700">
      You should receive an email in the next few seconds with a link to sign you in. Be sure to
      check your spam folder.
    </p>
  );
}

export default VerifyEmail;
