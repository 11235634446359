import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNodeById } from "../../../../state/selectors/boxDiagramSelectors";
import { RootState } from "../../../../state/slices";
import { addNode, deleteNode } from "src/state/slices/documentSlice";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/shared/BaseContextMenu";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { ArrowDirection } from "src/models/BoxDiagram/Edge";
import { navigateToDSM, navigateToICD } from "src/state/selectors/selectors";

interface NodeContextMenuProps {
  id: string;
  top?: number;
  left?: number;
  onClose?: () => void;
}

/**
 * Component that renders a context menu for a node
 */
function NodeContextMenu({ id, top, left, onClose, ...props }: NodeContextMenuProps) {
  const dispatch = useDispatch();
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);
  // Fetch selected specific node from the Redux
  const node = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, id)
  );
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);

  // Row index of the edge in the ICD table
  let edgeIndex = edges.findIndex((edge) => edge.source === id || edge.target === id);
  // Edge used to scroll to the DSM cell
  let edge = edges.find((edge) => edge.source === id);
  if (edge === undefined) {
    edge = edges.find((edge) => edge.target === id);
  }

  function handleDuplicateNode() {
    const type = node?.type;
    let position = { x: node?.position?.x + 50, y: node?.position?.y + 50 };

    console.log("NodeContextMenu: handleDuplicateNode: position: ", position);
    const data = node?.data;
    dispatch(addNode({ type, position, data, parentNode: node.parentNode }));
    onClose();
  }

  const handleDeleteNode = useCallback(() => {
    dispatch(deleteNode({ sourceNodeId: id }));
  }, []);

  function handleViewInICD() {
    navigateToICD(dispatch, edgeIndex);
  }

  function handleViewInDSM() {
    navigateToDSM(dispatch, edge);
  }

  // Early return if the node's position is undefined. Is this needed
  // if (!node?.position) return null;

  const styles = {
    top: top,
    left: left != undefined ? left - 220 : undefined,
  };

  return (
    <BaseContextMenu onClose={onClose} styles={styles}>
      <p className="mb-2 ml-2 text-lg">
        <small>
          Node ID: <b>{id}</b>
        </small>
      </p>
      <HorizontalDivider />
      <BaseContextMenuButton title={"Duplicate"} onClick={handleDuplicateNode} />
      <BaseContextMenuButton
        title={"View in ICD"}
        onClick={handleViewInICD}
        showChevron
        disabled={edgeIndex === -1}
      />
      <BaseContextMenuButton
        title={"View in DSM"}
        onClick={handleViewInDSM}
        showChevron
        disabled={edge === undefined || edge.data.arrow_direction === ArrowDirection.NoDirection}
      />
      <HorizontalDivider />
      <BaseContextMenuButton title={"Delete"} onClick={handleDeleteNode} />
    </BaseContextMenu>
  );
}

export default NodeContextMenu;
