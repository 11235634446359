export const NodeIdPrefix: string = "n-";
export const EdgeIdPrefix: string = "e-";

export const SourceHandlePrefix: string = "s-";
export const TargetHandlePrefix: string = "t-";

export const EDGE_TOOLBAR_ID: string = "n-0";

export const SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
export const CLIENT_URL = process.env.REACT_APP_API_CLIENT_URL;

export const APPLICATION_NAME = "Sinkly.co";

export enum STRIPE_PRODUCT {
  TEST_HOBBY = 1,
  TEST_INDIVIDUAL = 2,
  TEST_TEAM = 3,
  TEST_ENTERPRISE = 4,
  LIVE_HOBBY = 5,
  LIVE_INDIVIDUAL = 6,
  LIVE_TEAM = 7,
  LIVE_ENTERPRISE = 8,
}

export const CLIENT_ROUTE = {
  HOME: "/",
  WAITLIST: "/waitlist",
  DOCUMENT_HOME: "/documents",
  DOCUMENT: (id?: string) => {
    if (id) {
      return `/documents/${id}`;
    }
    return "/documents/:id";
  },
  PRICING: "/pricing",
  HACKER_PULSE: "/hacker-pulse",
  SIGN_IN: "/auth/sign-in",
  VERIFY_EMAIL: "/auth/verify-email",
};

export const SERVER_ROUTE = {
  DOCUMENT: {
    SAVE: `${SERVER_URL}/v1/document/save`,
    GET: `${SERVER_URL}/v1/document/get`,
    GET_ALL: `${SERVER_URL}/v1/document/get-all`,
    DELETE: `${SERVER_URL}/v1/document/delete`,
  },
  USER: {
    GET: `${SERVER_URL}/v1/user/get`,
    CREATE: `${SERVER_URL}/v1/user/create`,
  },
  STRIPE: {
    CREATE_CHECKOUT_SESSION: `${SERVER_URL}/v1/stripe/checkout-session`,
    CREATE_CHECKOUT_CHOOSE_PAYMENT: `${SERVER_URL}/v2/stripe/checkout-session`,
    CREATE_CUSTOMER_PORTAL_SESSION: `${SERVER_URL}/v1/stripe/customer-portal`,
  },
  SUBSCRIPTION: {
    GET: `${SERVER_URL}/v1/subscription/get`,
  },
  WAITLIST: {
    JOIN: `${SERVER_URL}/v1/waitlist/join`,
  },
};

export const LOCAL_STORAGE = {
  USER_ID: "userID",
};
