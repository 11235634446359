import React, { useEffect, useRef, useState } from "react";
import { CellType, DSMCell, getDSMCellLabel } from "../../../../models/DSMCell";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/slices";
import useOutsideClick from "src/hooks/useOutsideClick";
import { setNodeLabel } from "src/state/slices/documentSlice";
import { setSelectedTextMenu } from "src/state/slices/dsmSlice";

interface DSMTextAreaProps {
  data: DSMCell;
}
export function DSMTextArea({ data }: DSMTextAreaProps) {
  const dispatch = useDispatch();

  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  let cellContent: string = getDSMCellLabel({ cell: data, nodes });

  const [inputValue, setInputValue] = useState(cellContent);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useOutsideClick(textAreaRef, () => {
    handleOnBlur();
    dispatch(setSelectedTextMenu({ rowCol: null }));
  });

  useEffect(() => {
    if (textAreaRef.current) {
      // Focus the input and select all text
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length
      );

      // Automatically set the height of the textarea
      textAreaRef.current.style.height = "auto"; // Reset the height first
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, []);

  // Update the value and adjust height when the input changes
  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(e.target.value);

    // Automatically adjust the height of the textarea
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset the height first
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }

  // Update the value and dismiss when Enter is pressed
  function handleOnKeydown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleOnBlur();
    }
  }

  // OnBlur currently dismisses the textarea because the redux update causes a re-render
  // Note: This function is highjacked by useOutsideClick. It must be called manually
  function handleOnBlur() {
    if (data.type === CellType.RowHeader && inputValue !== cellContent) {
      dispatch(setNodeLabel({ nodeId: data.source, label: inputValue }));
    } else if (data.type === CellType.ColHeader && inputValue !== cellContent) {
      dispatch(setNodeLabel({ nodeId: data.target, label: inputValue }));
    }

    // Hide the text menu
    dispatch(setSelectedTextMenu({ rowCol: null }));
  }

  return (
    <div>
      <textarea
        ref={textAreaRef}
        className="absolute top-0 left-0 w-48 h-auto z-10 p-2 text-lg bg-white border border-indigo-500 rounded-md shadow-2xl overflow-hidden"
        style={{ resize: "none" }}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleOnKeydown}
      />
    </div>
  );
}
