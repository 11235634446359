import {
  ChevronDownSquare,
  ArrowLeftRight,
  MoveLeft,
  MoveRight,
  Minus,
  Plug,
  CornerRightDown,
  Redo,
  MoveUpRight,
  RouteOff,
  Trash2,
} from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { MainToolbar, ToolbarButton, VerticalDivider } from "../../Toolbars/BaseToolbar";
import { RootState } from "../../../../state/slices";
import { Edge, Node } from "reactflow";
import { ArrowDirection, EdgeData, EdgePathShape } from "../../../../models/BoxDiagram/Edge";
import { useDispatch, useSelector } from "react-redux";
import { ReactFlowInstanceContext } from "../../BlockDiagram";

import { updateEdgeData, updateEdgeToolbarPosition } from "src/state/reducers/boxDiagramReducers";
import { NodeData } from "src/models/BoxDiagram/Node";
import { deleteEdges, setArrowDirection, setSelectedEdge } from "src/state/slices/documentSlice";
import { dispatchSaveDiagramSnapshot } from "src/events/documentEvents";
import { getEdgeById } from "src/state/selectors/boxDiagramSelectors";
// import ConnectionPickerSectionV2 from "./ConnectionPickerV2";
import { ConnectionPicker } from "src/components/ICD/Columns/ICDConnectionCell";
import { childPosRelativeToCanvas } from "src/utils/boxDiagramUtils";
import EdgeLineStylePicker from "./EdgeLineStylePicker";

export enum EdgeBarMenuItem {
  Connection,
  LineShape,
  LineStyle,
}

/**
 * This component is implemented as a Node in React Flow
 */
function BaseEdgeToolbar() {
  const dispatch = useDispatch();
  const selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);
  const reactFlowInstance = useContext(ReactFlowInstanceContext);
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);

  const [selectedSubMenu, setSelectedSubMenu] = useState<EdgeBarMenuItem | null>(null);

  function onMenuClose() {
    // To do
  }

  // Update position each time the selected edge changes
  useEffect(() => {
    if (selectedEdge && reactFlowInstance) {
      const newPosition = calculateToolbarPosition(selectedEdge, nodes);

      dispatch(updateEdgeToolbarPosition({ position: newPosition }));
    }

    // Need to close the menu when the selected edge changes to avoid
    // weird behavior causing menu to show and hide when the user clicks on another edge
    if (!selectedEdge) {
      setSelectedSubMenu(null);
    }
  }, [selectedEdge, reactFlowInstance]);

  // If there's no selected edge, don't render the toolbar to avoid
  // unnecessary calculations and rendering when not visible
  if (!selectedEdge) return null;

  return (
    <div>
      <MainToolbar>
        <ConnectionPickerSection
          selectedSubMenu={selectedSubMenu}
          setSelectedSubMenu={setSelectedSubMenu}
        />
        <VerticalDivider />

        <ArrowDirectionPicker setSelectedSubMenu={setSelectedSubMenu} />
        <VerticalDivider />

        <EdgeLineShapePicker
          selectedSubMenu={selectedSubMenu}
          setSelectedSubMenu={setSelectedSubMenu}
        />
        <VerticalDivider />

        <EdgeLineStylePicker
          selectedSubMenu={selectedSubMenu}
          setSelectedSubMenu={setSelectedSubMenu}
        />
        <VerticalDivider />

        <RemoveEdgeButton selectedEdge={selectedEdge} />
      </MainToolbar>
    </div>
  );
}

interface RemoveEdgeButtonProps {
  selectedEdge: Edge<EdgeData> | null;
}

function RemoveEdgeButton({ selectedEdge }: RemoveEdgeButtonProps) {
  const dispatch = useDispatch();

  function removeEdge() {
    // Remove the edge from the document
    dispatch(deleteEdges({ edges: [selectedEdge] }));
    // Close the toolbar
    dispatch(setSelectedEdge({ edge: null }));
  }

  return (
    <div onClick={removeEdge}>
      <ToolbarButton tooltip="Delete connection">
        <Trash2 className="w-12" strokeWidth={1.3} size={26} />
      </ToolbarButton>
    </div>
  );
}

interface EdgeLineShapePickerProps {
  selectedSubMenu: EdgeBarMenuItem | null;
  setSelectedSubMenu: (selectedSubMenu: EdgeBarMenuItem | null) => void;
}
function EdgeLineShapePicker({ selectedSubMenu, setSelectedSubMenu }: EdgeLineShapePickerProps) {
  function toggleVisibility() {
    setSelectedSubMenu(EdgeBarMenuItem.LineShape);
  }

  return (
    <div onClick={toggleVisibility}>
      <ToolbarButton tooltip="Line shape">
        {getPathShapeIcon(EdgePathShape.SimpleBezier)}
      </ToolbarButton>
      {selectedSubMenu === EdgeBarMenuItem.LineShape && (
        <div className="absolute top-full mt-2">
          <LineShapeMenu />
        </div>
      )}
    </div>
  );
}

function LineShapeMenu() {
  const selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);

  return (
    <div className="flex p-1 bg-white rounded-lg shadow-lg">
      <LineShapeButton pathShape={EdgePathShape.SmoothStep} selectedEdge={selectedEdge} />
      <LineShapeButton pathShape={EdgePathShape.SimpleBezier} selectedEdge={selectedEdge} />
      <LineShapeButton pathShape={EdgePathShape.Straight} selectedEdge={selectedEdge} />
    </div>
  );
}

interface LineShapeButtonProps {
  pathShape: EdgePathShape;
  selectedEdge: Edge<EdgeData> | null;
}

function LineShapeButton({ pathShape, selectedEdge }: LineShapeButtonProps) {
  const dispatch = useDispatch();
  const selectedBtnStyle = "bg-gray-100";
  const isSelected = selectedEdge?.data?.temp_path_shape === pathShape;

  function handleOnClick() {
    dispatch(
      updateEdgeData({
        id: selectedEdge?.id,
        newData: { path_shape: pathShape, temp_path_shape: pathShape },
      })
    );
    dispatch(setSelectedEdge({ edge: null }));
  }

  function handleMouseEnter() {
    dispatch(updateEdgeData({ id: selectedEdge?.id, newData: { path_shape: pathShape } }));
  }

  function handleMouseLeave() {
    dispatch(
      updateEdgeData({
        id: selectedEdge?.id,
        newData: { path_shape: selectedEdge.data.temp_path_shape },
      })
    );
  }

  return (
    <div
      onClick={handleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={
        `flex justify-center items-center p-2 rounded-lg hover:bg-gray-100 w-11 h-11` +
        (isSelected ? ` ${selectedBtnStyle}` : "")
      }
    >
      {getPathShapeIcon(pathShape)}
    </div>
  );
}

function getPathShapeIcon(pathShape: EdgePathShape) {
  if (pathShape === EdgePathShape.SmoothStep) {
    return <CornerRightDown className="w-12" strokeWidth={1.3} size={30} />;
  }
  if (pathShape === EdgePathShape.SimpleBezier) {
    return <Redo className="w-12" strokeWidth={1.3} size={30} />;
  }
  if (pathShape === EdgePathShape.Straight) {
    return <MoveUpRight className="w-12" strokeWidth={1.3} size={30} />;
  }
  throw new Error("Invalid path shape");
}

interface ConnectionPickerSectionProps {
  selectedSubMenu: EdgeBarMenuItem | null;
  setSelectedSubMenu: (selectedSubMenu: EdgeBarMenuItem | null) => void;
}

function ConnectionPickerSection({
  selectedSubMenu,
  setSelectedSubMenu,
}: ConnectionPickerSectionProps) {
  const dispatch = useDispatch();
  const selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);
  const prevSelectedEdge = useRef(selectedEdge);

  function handleOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    setSelectedSubMenu(
      selectedSubMenu === EdgeBarMenuItem.Connection ? null : EdgeBarMenuItem.Connection
    );
  }

  // Close the menu each time we change the selected edge
  // This prevents the menu from remaining visible when the user clicks on another edge
  useEffect(() => {
    setSelectedSubMenu(null);
  }, [selectedEdge?.id]);

  function getIcon() {
    if (selectedSubMenu === EdgeBarMenuItem.Connection) {
      return <ChevronDownSquare className="w-12" strokeWidth={1.3} size={30} />;
    }
    return <Plug className="w-12" strokeWidth={1.3} size={25} />;
  }

  return (
    <div onClick={handleOnClick}>
      <ToolbarButton tooltip="Connections">{getIcon()}</ToolbarButton>
      {selectedSubMenu === EdgeBarMenuItem.Connection && (
        <div onClick={(e) => e.stopPropagation()} className="absolute top-full mt-2">
          {/* <ConnectionsMenu /> */}
          <ConnectionPicker edge={selectedEdge} allowDeleteLastConn={false} />
        </div>
      )}
    </div>
  );
}

interface ArrowDirectionPickerProps {
  setSelectedSubMenu: (selectedSubMenu: EdgeBarMenuItem | null) => void;
}
function ArrowDirectionPicker({ setSelectedSubMenu }: ArrowDirectionPickerProps) {
  const dispatch = useDispatch();
  // We use selected edge from boxDiagramUI to separate UI from data model
  const _selectedEdge = useSelector((state: RootState) => state.document.selectedEdge);
  // When we update arrow direction, we need to update the data model. We change the source and edge of the
  // selected edge and need to make sure that change is reflected in the _selectedEdge in boxDiagramUI
  const selectedEdge = useSelector((state: RootState) => {
    return getEdgeById(state, _selectedEdge?.id);
  });

  const [currentSelection, setCurrentSelection] = useState(
    selectedEdge?.data?.arrow_direction || ArrowDirection.NoDirection
  );

  // Function to cycle through the arrow locations
  function cycleArrowDirection() {
    // Close other menes when user interact with this menu
    setSelectedSubMenu(null);

    setCurrentSelection((prev: number) => {
      let nextSelection;
      if (prev === ArrowDirection.NoDirection) {
        nextSelection = ArrowDirection.ToTarget;
      } else if (prev === ArrowDirection.ToTarget) {
        nextSelection = ArrowDirection.ToSource;
      } else if (prev === ArrowDirection.ToSource) {
        nextSelection = ArrowDirection.Both;
      } else if (prev === ArrowDirection.Both) {
        nextSelection = ArrowDirection.NoDirection;
      } else {
        throw new Error("Invalid arrow direction");
      }

      // Dispatch the update inside the setState callback to ensure
      // the update is applied before the next render
      if (selectedEdge) {
        // Save state since cross document undo/redo is not supported yet
        dispatchSaveDiagramSnapshot(window);
        dispatch(setArrowDirection({ edge: selectedEdge, arrowDirection: nextSelection }));
      }

      return nextSelection;
    });
  }

  return (
    <div onClick={cycleArrowDirection}>
      <ToolbarButton tooltip={"Arrow direction"}>
        {getArrowDirection(currentSelection)}
      </ToolbarButton>
    </div>
  );
}

export function getArrowDirection(
  arrowDirection?: ArrowDirection,
  size: number = 30,
  width: string = "w-12",
  stroke: number = 1.3
) {
  if (arrowDirection === undefined) {
    return <RouteOff className={width} strokeWidth={stroke} size={size} />;
  }

  switch (arrowDirection) {
    case ArrowDirection.ToSource:
      return <MoveLeft className={width} strokeWidth={stroke} size={size} />;
    case ArrowDirection.ToTarget:
      return <MoveRight className={width} strokeWidth={stroke} size={size} />;
    case ArrowDirection.Both:
      return <ArrowLeftRight className={width} strokeWidth={stroke} size={size} />;
    case ArrowDirection.NoDirection:
      return <Minus className={width} strokeWidth={stroke} size={size} />;
  }
}

function calculateToolbarPosition(edge: Edge<EdgeData>, nodes: Node<NodeData>[]) {
  // Placeholder for X offset, currently set to 0 by default
  const xOffset = 0;
  const yOffset = -75;

  const sourceNode = nodes.find((node) => node.id === edge.source);
  const targetNode = nodes.find((node) => node.id === edge.target);

  let sourcePosition = sourceNode?.position;
  let targetPosition = targetNode?.position;

  // If the source or target node has a parent, calculate the position of the child relative to the canvas
  if (sourceNode.parentNode) {
    const parentPosition = nodes.find((node) => node.id === sourceNode.parentNode)?.position;
    sourcePosition = childPosRelativeToCanvas(parentPosition, sourcePosition);
  }

  if (targetNode.parentNode) {
    const parentPosition = nodes.find((node) => node.id === targetNode.parentNode)?.position;
    targetPosition = childPosRelativeToCanvas(parentPosition, targetPosition);
  }

  // This can happen when user switches between documents. Just place toolbar anywhere in this case
  if (!sourcePosition || !targetPosition) {
    return { x: 0, y: 0 };
  }

  // Calculate the midpoint of the edge and apply X offset
  let midX = (sourcePosition.x + targetPosition.x) / 2 + xOffset;
  let midY = sourcePosition.y + yOffset;

  // Get viewport dimensions
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // Ensure the toolbar is always rendered on the screen
  // if (midX < 0) {
  //   midX = 0;
  // } else if (midX > viewportWidth) {
  //   midX = viewportWidth;
  // }

  // if (midY < 0) {
  //   midY = 0;
  // } else if (midY > viewportHeight) {
  //   midY = viewportHeight;
  // }

  return { x: midX, y: midY };
}

export default BaseEdgeToolbar;
