import { ChevronRight } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import useOutsideClick from "src/hooks/useOutsideClick";

interface BaseContextMenuProps {
  children?: React.ReactNode;
  className?: string;
  styles?: React.CSSProperties;
  onClose: () => void;
}

function BaseContextMenu({ children, className, styles, onClose }: BaseContextMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  // Close menu when clicking outside
  useOutsideClick(menuRef, onClose);

  function handleStopPropagation(event: React.MouseEvent) {
    event.stopPropagation();
  }

  function handleContextMenu(event: React.MouseEvent) {
    // Prevent the default context menu from showing up
    event.preventDefault();
    // Don't show another context menu when right clicking on the context menu
    event.stopPropagation();
    // Close the context menu
    onClose();
  }

  return (
    <div
      ref={menuRef}
      className={"absolute z-30 py-1 w-56 bg-white rounded-md drop-shadow-2xl " + className || ""}
      style={styles}
      onClick={handleStopPropagation}
      onContextMenu={handleContextMenu}
    >
      <div>{children}</div>
    </div>
  );
}

interface BaseContextMenuButtonProps {
  title: string;
  showChevron?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

function BaseContextMenuButton({
  title,
  showChevron,
  disabled,
  onClick,
}: BaseContextMenuButtonProps) {
  function handleContextMenu(event: React.MouseEvent) {
    event.preventDefault();
    if (disabled) return;
    onClick();
  }

  const classNames = disabled ? "text-gray-400" : "";

  return (
    <button
      className={`flex justify-between items-center w-full text-left px-4 py-2 hover:bg-gray-100 ${classNames}`}
      onClick={onClick}
      onContextMenu={handleContextMenu}
      disabled={disabled}
    >
      <span>{title}</span>
      {showChevron && <ChevronRight size={20} />}
      {/* Conditional rendering of the chevron */}
    </button>
  );
}

export { BaseContextMenu, BaseContextMenuButton };
