// src/App.tsx

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./components/LandingPage/LandingPage";
import SignIn from "./components/LandingPage/SignIn";
import VerifyEmail from "./components/LandingPage/VerifyEmail";
import DocumentsPage from "./components/Documents/DocumentsPage";
import ProtectedRoutes from "./components/shared/ProtectedRoutes";
import { CLIENT_ROUTE } from "./utils/constants";
import PricingPage from "./components/LandingPage/PricingPage";
import ChooseWhatToPay from "./components/LandingPage/ChooseWhatToPay";
import LandingPageWaitList from "./components/LandingPage/LandingPageWaitList";
import "./input.css";
import JoinWaitlist from "./components/LandingPage/JoinWaitlist";

function App() {
  return (
    // Enables Redux store
    <Provider store={store}>
      {/* Enables Redux Persist */}
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path={CLIENT_ROUTE.DOCUMENT_HOME} element={<DocumentsPage />} />
            </Route>
            <Route path={CLIENT_ROUTE.DOCUMENT()} element={<Dashboard />} />
            <Route path={CLIENT_ROUTE.HOME} element={<LandingPage />} />
            <Route path={CLIENT_ROUTE.WAITLIST} element={<JoinWaitlist />} />
            <Route path={CLIENT_ROUTE.SIGN_IN} element={<SignIn />} />
            <Route path={CLIENT_ROUTE.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={CLIENT_ROUTE.PRICING} element={<PricingPage />} />
            <Route path={CLIENT_ROUTE.HACKER_PULSE} element={<ChooseWhatToPay />} />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to={CLIENT_ROUTE.HOME} />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
