import { useCallback, useEffect, useState } from "react";
import { Edge, Node, useReactFlow } from "reactflow";
import { NodeData } from "../models/BoxDiagram/Node";
import { EdgeData } from "../models/BoxDiagram/Edge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/slices";
import { dispatchCloseMenus } from "../events/documentEvents";
import { replaceAllEdges, replaceAllNodes } from "src/state/reducers/boxDiagramReducers";

type UseUndoRedoOptions = {
  maxHistorySize: number;
  enableShortcuts: boolean;
};

type UseUndoRedo = (options?: UseUndoRedoOptions) => {
  undo: () => void;
  redo: () => void;
  takeSnapshot: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

type HistoryItem = {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
};

const defaultOptions: UseUndoRedoOptions = {
  maxHistorySize: 100,
  enableShortcuts: true,
};

// https://redux.js.org/usage/implementing-undo-history
export const useUndoRedo: UseUndoRedo = ({
  maxHistorySize = defaultOptions.maxHistorySize,
  enableShortcuts = defaultOptions.enableShortcuts,
} = defaultOptions) => {
  const dispatch = useDispatch();
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);

  // the past and future arrays store the states that we can jump to
  const [past, setPast] = useState<HistoryItem[]>([]);
  const [future, setFuture] = useState<HistoryItem[]>([]);

  const takeSnapshot = useCallback(() => {
    // push the current graph to the past state
    setPast((past) => [
      ...past.slice(past.length - maxHistorySize + 1, past.length),
      { nodes: nodes, edges: edges },
    ]);

    // whenever we take a new snapshot, the redo operations need to be cleared to avoid state mismatches
    setFuture([]);
  }, [nodes, edges, maxHistorySize]);

  const undo = useCallback(() => {
    // get the last state that we want to go back to
    // const pastState = past;
    const presentState = { nodes: nodes, edges: edges };

    if (past.length > 0) {
      // first we remove the state from the history
      const previous: HistoryItem = past[past.length - 1];
      setPast((past) => past.slice(0, past.length - 1));
      // we store the current graph for the redo operation
      setFuture((future) => [presentState, ...future]);
      // now we can set the graph to the past state
      dispatch(replaceAllNodes({ nodes: previous.nodes }));
      dispatch(replaceAllEdges({ edges: previous.edges }));

      // Close all menus, if open
      dispatchCloseMenus(window);
    }
  }, [replaceAllEdges, replaceAllEdges, nodes, edges, past]);

  const redo = useCallback(() => {
    const presentState = { nodes: nodes, edges: edges };
    // const futureState = future[future.length - 1];
    if (future.length > 0) {
      const next = future[0];
      setFuture((future) => future.slice(1));
      setPast((past) => [...past, presentState]);
      dispatch(replaceAllNodes({ nodes: next.nodes }));
      dispatch(replaceAllEdges({ edges: next.edges }));

      // Close all menus, if open
      dispatchCloseMenus(window);
    }
  }, [replaceAllEdges, replaceAllEdges, nodes, edges, future]);

  useEffect(() => {
    // this effect is used to attach the global event handlers
    if (!enableShortcuts) {
      return;
    }

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "z" && (event.ctrlKey || event.metaKey) && event.shiftKey) {
        redo();
      } else if (event.key === "z" && (event.ctrlKey || event.metaKey)) {
        undo();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [undo, redo, enableShortcuts]);

  return {
    undo,
    redo,
    takeSnapshot,
    canUndo: !past.length,
    canRedo: !future.length,
  };
};

export default useUndoRedo;
