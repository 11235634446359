// models/User.ts (client)
import { User as FirebaseUser } from "firebase/auth";
export type User = {
  created_at_ms: number;
  document_ids: string[];
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  phone_number?: string;
  photo_url?: string;
};

export const createUserFromFirebaseUser = (firebaseUser: FirebaseUser): User => {
  return {
    created_at_ms: Date.now(),
    document_ids: [],
    email: firebaseUser.email,
    first_name: firebaseUser.displayName
      ? firebaseUser.displayName.split(" ")[0]
      : firebaseUser.email.split("@")[0],
    id: firebaseUser.uid,
    last_name: firebaseUser.displayName ? firebaseUser.displayName.split(" ")[1] : "",
    phone_number: firebaseUser.phoneNumber,
    photo_url: firebaseUser.photoURL,
  };
};
