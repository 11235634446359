import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { FloatingCopyButton } from "./ICDDescriptionCell";
import { setInterfaceIdPrefix } from "src/state/slices/documentSlice";
import { EdgeData } from "src/models/BoxDiagram/Edge";
import { Edge } from "reactflow";
import { EdgeIdPrefix } from "src/utils/constants";

interface ICDInterfaceIdCellProps {
  edge: Edge<EdgeData>;
  rowIndex: number;
  colID: string;
  onChange?: (newValue: string) => void;
}

function ICDInterfaceIdCell({ edge, rowIndex, colID, onChange }: ICDInterfaceIdCellProps) {
  const [showButton, setShowButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const cellRef = useRef<HTMLDivElement>(null);
  const interfaceIDPrefix = useSelector(
    (state: RootState) => state.document.documentContainer.id_prefix
  );

  const edgeIDSuffix = edge.id.replace(EdgeIdPrefix, "");
  const interfaceID = `${interfaceIDPrefix}_${edgeIDSuffix}`;
  // Focus the input when editing starts
  useEffect(() => {
    // Automatically exit edit mode when clicking outside the cell
    function handleClickOutside(event: MouseEvent) {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        // if (onChange) onChange(editValue);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, onChange]);

  function handleOnClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  function handleStopEditing() {
    setIsEditing(false);
    setShowButton(false);
  }

  return (
    <div
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      ref={cellRef}
      className="relative flex items-start justify-start px-3 pt-3 h-full text-lg"
      onClick={handleOnClick}
    >
      {isEditing && (
        <PrefixPicker interfaceIDPrefix={interfaceIDPrefix} handleStopEditing={handleStopEditing} />
      )}
      {/* {showButton && !isEditing && <FloatingCopyButton text={formatTextForCopy(legendItems)} />} */}
      <div className="text-md">{interfaceID}</div>

      {showButton && !isEditing && <FloatingCopyButton text={interfaceID} />}
    </div>
  );
}

/**
 * PrefixPicker - A component that allows users to select a direction for a connection.
 */
interface PrefixPickerProps {
  interfaceIDPrefix: string;
  handleStopEditing: () => void;
}
function PrefixPicker({ interfaceIDPrefix, handleStopEditing }: PrefixPickerProps) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(interfaceIDPrefix);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleOnBlur() {
    // Remove all whitespace and convert to uppercase
    const formattedValue = value.replace(/\s/g, "").toUpperCase();
    dispatch(setInterfaceIdPrefix({ prefix: formattedValue }));
  }

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      handleOnBlur();
      handleStopEditing();
    }
  }

  return (
    <div className="absolute top-0 left-0 flex flex-col  w-60 rounded-lg shadow-lg bg-white border  z-50">
      <PrefixPickerTitle />
      <input
        className="m-3 border border-gray-300 rounded-md p-1"
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
      />
    </div>
  );
}

function PrefixPickerTitle() {
  return (
    <span className="text-neutral-500 pl-3 py-2 text-sm font-medium">Set interface id prefix</span>
  );
}

export default ICDInterfaceIdCell;
