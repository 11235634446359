// src/models/Subscription.ts

export type Subscription = {
  created_at_ms: number;
  current_period_end: number;
  current_period_start: number;
  customer_id: string;
  product_id: string;
  product_type: number;
  subscription_id: string;
  stripe_status: string;
  stripe_trial_start: number;
  stripe_trial_end: number;
  updated_at_ms: number;
};

export type Subscriptions = {
  user_id: string;
  subscriptions: Subscription[];
};

export enum StripeProductType {
  UNKNOWN = 0,
  HOBBY = 1,
  INDIVIDUAL = 2,
  ORGANIZATION = 3,
  ENTERPRISE = 4,
}
