import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/shared/BaseContextMenu";
import { ArrowDirection } from "src/models/BoxDiagram/Edge";
import { DSMCell } from "src/models/DSMCell";
import { applyNewNodeChanges } from "src/state/reducers/boxDiagramReducers";
import { navigateToBoxDiagram, navigateToICD } from "src/state/selectors/selectors";
import { RootState } from "src/state/slices";
import { setZoomToNode } from "src/state/slices/BoxDiagram/boxDiagramUISlice";
import { DSMContextMenuState, setContextMenu, setSelectedState } from "src/state/slices/dsmSlice";
import { setSelectedRow } from "src/state/slices/icdSlice";
import { setSelectedSection } from "src/state/slices/sidebarSlice";
import { SideNavigationBarSection } from "src/state/slices/uiSlice";

interface DSMContextMenuProps {
  data: DSMCell;
  setShowEditConnections: (showEditConnections: boolean) => void;
}
function DSMContextMenu({ data, setShowEditConnections }: DSMContextMenuProps) {
  const dispatch = useDispatch();
  const contextMenu: DSMContextMenuState = useSelector((state: RootState) => state.dsm.contextMenu);

  const edgeIndex = useSelector((state: RootState) =>
    state.document.documentContainer.edges.findIndex((edge) => {
      if (edge.source === data.source && edge.target === data.target) {
        return true;
      }

      if (
        edge.target === data.source &&
        edge.source === data.target &&
        edge.data.arrow_direction === ArrowDirection.Both
      ) {
        return true;
      }

      return false;
    })
  );

  function handleCloseContextMenu() {
    dispatch(setContextMenu({ ctxMenuState: { position: null, cell: null } }));
  }

  const handleICDClick = () => {
    navigateToICD(dispatch, edgeIndex);
    handleCloseContextMenu();
  };

  const handleDiagramClick = () => {
    navigateToBoxDiagram(dispatch, data.source);
    handleCloseContextMenu();
  };

  function handleEditConnectionsClick() {
    setShowEditConnections(true);
    handleCloseContextMenu();
  }

  useEffect(() => {
    // Highlight the cell
    // dispatch(setSelectedState({ rowCol: { source: data.source, target: data.target } }));
  }, []);

  return (
    <BaseContextMenu
      onClose={handleCloseContextMenu}
      styles={{ top: contextMenu.position.y, left: contextMenu.position.x }}
    >
      <BaseContextMenuButton title={"Manage connections"} onClick={handleEditConnectionsClick} />
      <HorizontalDivider />
      <BaseContextMenuButton
        title={"View in Diagram"}
        onClick={handleDiagramClick}
        disabled={edgeIndex === -1}
        showChevron
      />
      <BaseContextMenuButton
        title={"View in ICD"}
        onClick={handleICDClick}
        disabled={edgeIndex === -1}
        showChevron
      />
    </BaseContextMenu>
  );
}

export default DSMContextMenu;
