import { NodeToolbar, Position, XYPosition, useReactFlow } from "reactflow";
import { ReactNode, useState } from "react";
import { addCloseMenusLister } from "../../../events/documentEvents";

interface BaseToolbarProps {
  showNodeToolbar: boolean;
  nodeOrEdgeId: string;
  nodePos?: XYPosition;
  onMenuClose: () => void;
  children?: ReactNode;
}

/**
 * This toolbar is used as a common interface for all node toolbars. It handles
 * closing all other toolbars when the user clicks on the grid or another node and
 * provides a common layout for all toolbars.
 */
function BaseToolbar({ showNodeToolbar, nodeOrEdgeId, onMenuClose, children }: BaseToolbarProps) {
  // Event is fired when the user clicks on the grid, a node, or an edge. This hides
  // all visible submenus. We pass nodeOrEdgeId to close all menus beside our own.
  addCloseMenusLister(() => {
    onMenuClose();
  }, nodeOrEdgeId);

  return (
    <NodeToolbar isVisible={showNodeToolbar} position={Position.Top} style={{ marginTop: -25 }}>
      {/* Main Toolbar body*/}
      <MainToolbar>{children}</MainToolbar>
    </NodeToolbar>
  );
}

export function VerticalDivider() {
  return <div className="w-px h-10 bg-gray-300"></div>;
}

interface ToolbarButtonProps {
  children: ReactNode;
  onClick?: () => void;
  tooltip?: string;
}

export function ToolbarButton({ children, onClick, tooltip }: ToolbarButtonProps) {
  const [isHovered, setIsHovered] = useState(false);
  // Used to hide tooltip after first click
  const [hasClicked, setHasClicked] = useState(false);

  // Show tooltip when mouse enters
  function handleMouseEnter() {
    if (!hasClicked) {
      setIsHovered(true);
    }
  }

  // Hides tooltip when mouse enters
  function handleMouseLeave() {
    if (!hasClicked) {
      setIsHovered(false);
    }
  }

  function handleOnClick() {
    // Call onClick if provided
    if (onClick) onClick();
    // Hide tooltip when user make a selection. Otherwise, the tooltip will
    // awkwardly remain visible
    setIsHovered(false);
    // Set click flag to hide tooltip after first click
    setHasClicked(true);
    // Reset click flag after a short timeout
    setTimeout(() => setHasClicked(false), 0);
  }

  return (
    <div className="relative">
      {/* Wrap in a relative div to position tooltip */}
      <button
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="flex justify-center items-center h-12 hover:bg-gray-100 rounded-md"
      >
        {children}
      </button>
      {isHovered && tooltip && (
        <div className="absolute w-max px-2 py-1 bg-black text-white text-sm rounded-md left-1/2 transform -translate-x-1/2 -top-10">
          {tooltip}
        </div>
      )}
    </div>
  );
}

// MainToolbarProps type
type MainToolbarProps = {
  children: ReactNode;
};

// MainToolbar component
export function MainToolbar({ children }: MainToolbarProps) {
  // Stop propagation so we don't deselect the node/edge when
  // interacting with the toolbar
  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  // Renders children within the MainToolbar layout
  return (
    <div
      onClick={stopPropagation}
      className="flex items-center justify-center h-14 px-2 space-x-2 bg-white rounded-xl shadow-md"
    >
      {children}
    </div>
  );
}

export default BaseToolbar;
