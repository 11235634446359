import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { FloatingCopyButton } from "./ICDDescriptionCell";
import { Edge } from "reactflow";
import { ArrowDirection, EdgeData } from "src/models/BoxDiagram/Edge";
import { edgeToDSMId } from "src/models/DSMCell";
import { getNodeById } from "src/state/selectors/boxDiagramSelectors";
import { navigateToDSM } from "src/state/selectors/selectors";

interface ICDDsmIdCellProps {
  edge: Edge<EdgeData>;
  columnSize: number;
  rowIndex: number;
  colID: string;
  onChange?: (newValue: string) => void;
}

function ICDDsmIdCell({ edge, rowIndex, colID, onChange }: ICDDsmIdCellProps) {
  const [showButton, setShowButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const cellRef = useRef<HTMLDivElement>(null);
  const dsmID = useSelector((state: RootState) => {
    return edgeToDSMId(edge, state.document.documentContainer.nodes);
  });
  const isNoConnection = edge?.data.arrow_direction == ArrowDirection.NoDirection;

  // Focus the input when editing starts
  useEffect(() => {
    // Automatically exit edit mode when clicking outside the cell
    function handleClickOutside(event: MouseEvent) {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        // if (onChange) onChange(editValue);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, onChange]);

  function handleOnClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  return (
    <div
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      ref={cellRef}
      className="relative flex items-start justify-start px-3 pt-3 h-full text-lg"
      onClick={handleOnClick}
    >
      {isEditing && <PrefixPicker edge={edge} dsmID={dsmID} />}
      {showButton && !isNoConnection && <FloatingCopyButton text={dsmID} />}
      <div className="text-md">{dsmID}</div>
    </div>
  );
}

interface PrefixPickerProps {
  edge: Edge<EdgeData>;
  dsmID: string;
}

function PrefixPicker({ edge, dsmID }: PrefixPickerProps) {
  const isNoConnection = edge?.data.arrow_direction === ArrowDirection.NoDirection;

  return (
    <div className="absolute top-0 -left-10 flex flex-col w-64 py-2 rounded-lg shadow-lg bg-white border z-50">
      {isNoConnection ? (
        <NoConnectionTitle edge={edge} />
      ) : (
        <>
          <PrefixPickerTitle dsmID={dsmID} />
          <ViewInDiagramButton edge={edge} />
        </>
      )}
    </div>
  );
}

function ViewInDiagramButton({ edge }: { edge: Edge<EdgeData> }) {
  const dispatch = useDispatch();
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);

  function handleViewInDSM() {
    navigateToDSM(dispatch, edge);
  }

  return (
    <button
      onClick={handleViewInDSM}
      className="my-1 mx-4 py-2 text-sm font-medium rounded border border-gray-300 text-neutral-500 hover:text-blue-800 hover:border-blue-800"
    >
      View in DSM
    </button>
  );
}

function PrefixPickerTitle({ dsmID }: { dsmID: string }) {
  return (
    <span className="text-neutral-500 pl-3 py-2 text-sm font-medium">
      Click to view in DSM: {dsmID}
    </span>
  );
}

function NoConnectionTitle({ edge }: { edge: Edge<EdgeData> }) {
  const sourceNode = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, edge.source)
  );
  const targetNode = useSelector((state: RootState) =>
    getNodeById(state.document.documentContainer.nodes, edge.target)
  );

  return (
    <div className="text-neutral-500 pl-3 py-2 text-sm font-medium">
      <div>No active connection between components:</div>
      <div className="mt-4">
        {sourceNode?.data.label} and {targetNode?.data.label}
      </div>
    </div>
  );
}

export default ICDDsmIdCell;
