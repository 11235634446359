export type ICDCell = {
  rowIndex: number;
  colID: string;
};

export enum ICDHeader {
  RowNumber = "rowNumber",
  InterfaceID = "interfaceId",
  Component1 = "component1",
  Direction = "direction",
  Component2 = "component2",
  Connections = "connections",
  Description = "description",
  Responsibility = "responsibility",
  DSMID = "dsmId",
}

export function columnTypeToName(type: ICDHeader): string {
  switch (type) {
    case ICDHeader.RowNumber:
      return "Row Number";
    case ICDHeader.InterfaceID:
      return "Interface ID";
    case ICDHeader.Component1:
      return "Component 1";
    case ICDHeader.Direction:
      return "Direction";
    case ICDHeader.Component2:
      return "Component 2";
    case ICDHeader.Connections:
      return "Connection Type";
    case ICDHeader.Description:
      return "Description";
    case ICDHeader.Responsibility:
      return "Responsibility";
    case ICDHeader.DSMID:
      return "DSM ID";
  }
}
