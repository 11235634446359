import { LayoutGrid, Plus } from "lucide-react";
import { CreateDocumentButton } from "./DocumentsPage";

function DocumentNoBodyView() {
  return (
    <div className="flex flex-col items-center justify-center mt-40 my-20 text-neutral-700">
      <LayoutGrid size={50} />
      <div className="mt-2 font-medium text-lg">No documents</div>
      <CreateDocumentButton />
    </div>
  );
}


export default DocumentNoBodyView;
