// src/components/ICD/ICD.tsx
import React, { RefObject, useEffect, useReducer, useRef, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Header,
  Row,
} from "@tanstack/react-table";
import { Table } from "@tanstack/react-table";

import "./sizing2.css";
import { ICDHeader, columnTypeToName } from "../../models/ICDRow";
import {
  Link, // For InterfaceID
  Cpu, // For Component1
  Shuffle, // For Direction
  Layers, // For Component2
  Plug, // For ConnectionType
  FileText, // For Description
  UserCheck, // For Responsibility
  Hash, // For DSMID
  // Backup icons
  ArrowLeftRight,
  Paperclip,
  Server,
  Repeat,
  Box,
  AlignLeft,
  UserPlus,
  Tag,
  Link2,
  Anchor,
} from "lucide-react";
import ICDDescriptionCell from "./Columns/ICDDescriptionCell";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import ICDConnectionCell from "./Columns/ICDConnectionCell";
import ICDDirectionCell from "./Columns/ICDDirectionCell";
import ICDInterfaceIdCell from "./Columns/ICDInterfaceIdCell";
import { ArrowDirection, EdgeData } from "src/models/BoxDiagram/Edge";
import { Edge, Node } from "reactflow";
import ICDDsmIdCell from "./Columns/ICDDsmIdCell";
import ICDComponentCell from "./Columns/ICDComponentCell";
import { setSelectedRow } from "src/state/slices/icdSlice";
import useOutsideClick from "src/hooks/useOutsideClick";
import ICDRowNumberCell from "./Columns/ICDRowNumberCell";
import ICDResponsibilityCell from "./Columns/ICDResponsibilityCell";
import { getMemoizedEdgesNotConnectedToFrame } from "src/state/selectors/boxDiagramSelectors";

/**
 * <thead> represents the block of rows that comprise the column headers of the table.
 * <tbody> encloses the body of the table, where data rows are displayed.
 * <tr> is a table row element that contains cells of the table in a single row.
 * <th> is a table header cell that typically appears in table rows within <thead>. It defines a header for a column.
 * <td> is a table data cell and contains the actual data of the table appearing in rows within <tbody>.
 */

// src/components/ICD/ICD.tsx
const defaultColumnDefs: ColumnDef<Edge<EdgeData>>[] = [
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.RowNumber,
    header: () => <Hash size={20} color="gray" />,
    cell: (info) => (
      <ICDRowNumberCell
        nodeID={(info.getValue() as Edge<EdgeData>).target}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: false,
    size: 20,
    minSize: 20,
    maxSize: 20,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.InterfaceID,
    header: () => <CustomHeaderCell value={ICDHeader.InterfaceID} />,
    cell: (info) => {
      // console.log("info", info.row.getAllCells());
      console.log("info", info.column.columnDef.minSize, info.column.getSize());

      return (
        <ICDInterfaceIdCell
          edge={info.getValue() as Edge<EdgeData>}
          rowIndex={info.row.index}
          colID={info.cell.id}
        />
      );
    },
    enableResizing: true,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.Component1,
    header: () => <CustomHeaderCell value={ICDHeader.Component1} />,
    cell: (info) => (
      <ICDComponentCell
        nodeID={(info.getValue() as Edge<EdgeData>).source}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.Direction,
    header: () => <CustomHeaderCell value={ICDHeader.Direction} />,
    cell: (info) => (
      <ICDDirectionCell
        edge={info.getValue() as Edge<EdgeData>}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.Component2,
    header: () => <CustomHeaderCell value={ICDHeader.Component2} />,
    cell: (info) => (
      <ICDComponentCell
        nodeID={(info.getValue() as Edge<EdgeData>).target}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.Description,
    header: () => <CustomHeaderCell value={ICDHeader.Description} />,
    cell: (info) => (
      <ICDDescriptionCell
        edge={info.getValue() as Edge<EdgeData>}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
    size: 400, // Column size
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.Connections,
    header: () => <CustomHeaderCell value={ICDHeader.Connections} />,
    cell: (info) => (
      <ICDConnectionCell
        edge={info.getValue() as Edge<EdgeData>}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.Responsibility,
    header: () => <CustomHeaderCell value={ICDHeader.Responsibility} />,
    cell: (info) => (
      <ICDResponsibilityCell
        edge={info.getValue() as Edge<EdgeData>}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
  },
  {
    accessorFn: (edge) => edge,
    id: ICDHeader.DSMID,
    header: () => <CustomHeaderCell value={ICDHeader.DSMID} />,
    cell: (info) => (
      <ICDDsmIdCell
        edge={info.getValue() as Edge<EdgeData>}
        columnSize={info.column.getSize()}
        rowIndex={info.row.index}
        colID={info.cell.id}
      />
    ),
    enableResizing: true,
  },
];

/**
 * ICD - Main component for rendering a table with resizable columns.
 * It initializes the table with data and column definitions.
 */
export function ICD() {
  // 🔧🏗️🚨 This is VERY inefficient. We re-render the entire table every time any edge changes.
  // Instead we should pass the length of the array to the table, derive each edge based on
  // the index, and then only re-render the rows that have changed.
  const edges = useSelector((state: RootState) => getMemoizedEdgesNotConnectedToFrame(state));
  const selectedRow = useSelector((state: RootState) => state.icd.selectedRow);
  const [columns] = useState<ColumnDef<Edge<EdgeData>>[]>(() => [...defaultColumnDefs]);

  // Used to enable scrolling to the selected row
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  // Scroll to the selected row, if any
  useEffect(() => {
    if (tableBodyRef.current && selectedRow) {
      // Scroll one passed the selected to bring row into view
      const scrollIndex = selectedRow + 2 < edges.length ? selectedRow + 2 : selectedRow;
      const rowToScroll = tableBodyRef.current.children[scrollIndex] as HTMLElement;
      rowToScroll?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, []);

  const table = useReactTable<Edge<EdgeData>>({
    data: edges,
    columns,
    columnResizeMode: "onChange", // Resize columns on every drag event
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 200, //starting column size
      minSize: 200, //enforced during column resizing
    },
  });

  // Component rendering logic
  return (
    <div className="p-2 ">
      <table style={{ width: table.getCenterTotalSize() }}>
        <TableHeaderGroup table={table} />
        <TableBody table={table} tableBodyRef={tableBodyRef} />
      </table>
    </div>
  );
}

/**
 * Renders a header row for a table with provided column headers.
 */
function TableHeaderGroup({ table }: { table: Table<Edge<EdgeData>> }) {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeader key={header.id} header={header} />
          ))}
        </tr>
      ))}
    </thead>
  );
}

/**
 * TableHeader - Renders an individual header cell in the table.
 * Includes a Resizer component for resizing the column.
 */
const TableHeader = React.memo(({ header }: { header: Header<Edge<EdgeData>, unknown> }) => {
  return (
    <th
      className="border-r border-l border-b border-gray-300"
      key={header.id}
      colSpan={header.colSpan}
      // style={{ width: header.getSize() }}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      <Resizer header={header} />
    </th>
  );
});

/**
 * Resizer - A component attached to a table header for resizing the column.
 */
function Resizer({ header }: { header: Header<Edge<EdgeData>, unknown> }) {
  const tableInstance = header.getContext().table; // Access the table instance from the header context

  return (
    <div
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
    />
  );
}

/**
 * TableBody - Renders the body of the table.
 * Iterates over each row and renders it using the TableRow component.
 * @param {Table<Edge<EdgeData>>} table - The table instance containing row data.
 */
function TableBody({
  table,
  tableBodyRef,
}: {
  table: Table<Edge<EdgeData>>;
  tableBodyRef: RefObject<HTMLTableSectionElement>;
}) {
  return (
    <tbody ref={tableBodyRef}>
      {table.getRowModel().rows.map((row) => (
        <TableRow key={row.id} row={row} />
      ))}
    </tbody>
  );
}

/**
 * TableRow - Renders a single row in the table.
 * Iterates over each cell in the row and renders it.
 * @param {Row<Edge<EdgeData>>} row - The row data to be rendered.
 */
function TableRow({ row }: { row: Row<Edge<EdgeData>> }) {
  const dispatch = useDispatch();
  const selectedRow = useSelector((state: RootState) => state.icd.selectedRow);
  const rowRef = useRef<HTMLTableRowElement>(null); // Ref for the entire table

  // Remove row highlight when clicking outside the table
  useOutsideClick(rowRef, () => {
    dispatch(setSelectedRow({ rowIndex: null }));
  });

  const rowStyle = row.index === selectedRow ? "bg-blue-50" : "";

  return (
    <tr ref={rowRef} className={rowStyle}>
      {row.getVisibleCells().map((cell) => (
        <td
          className="h-16 border border-gray-300"
          key={cell.id}
          style={{ width: cell.column.getSize() }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}

function CustomHeaderCell({ value }: { value: ICDHeader }) {
  // Custom rendering logic based on cellInfo
  return (
    <div className="flex items-center space-x-2 p-2 ">
      <div>{getIconForICDColumn(value)}</div>
      <div className="truncate  text-gray-500 font-medium">{columnTypeToName(value)}</div>
    </div>
  );
}

// Function to get the icon component for a given ICDColumnType
export function getIconForICDColumn(columnType: ICDHeader): JSX.Element {
  switch (columnType) {
    case ICDHeader.InterfaceID:
      return <Link2 size={20} color="gray" />;
    // Backup choices: <Paperclip />, <Tag />
    case ICDHeader.Component1:
      return <Box size={20} color="gray" />;
    // Backup choices: <Server />, <Box />
    case ICDHeader.Direction:
      return <ArrowLeftRight size={20} color="gray" />;
    // Backup choices: <Repeat />, <Connect />
    case ICDHeader.Component2:
      return <Box size={20} color="gray" />;
    // Backup choices: <Box />, <Stack />
    case ICDHeader.Connections:
      return <Plug size={20} color="gray" />;
    // Backup choices: <Connect />, <Link2 />
    case ICDHeader.Description:
      return <FileText size={20} color="gray" />;
    // Backup choices: <AlignLeft />, <File />
    case ICDHeader.Responsibility:
      return <UserCheck size={20} color="gray" />;
    // Backup choices: <UserPlus />, <User />
    case ICDHeader.DSMID:
      return <Link size={20} color="gray" />;
    // Backup choices: <Tag />, <Anchor />
    default:
      return <div />; // Default fallback
  }
}

export default ICD;
