import { ArrowRight, Check, Coffee, DraftingCompass, Sparkle, Sparkles } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { APPLICATION_NAME, CLIENT_ROUTE } from "src/utils/constants";
import { useEffect } from "react";
import {
  BetaUsersSection,
  LandingPageBottomBottom,
  LandingPageFooter,
  LandingPageVideoPlayer,
  Toolbar,
} from "./LandingPage";
import { Color } from "src/models/BoxDiagram/Colors";

function LandingPageWaitList() {
  useEffect(() => {
    // Function to check the hash and perform the scroll
    const checkHashAndScroll = () => {
      const hash = window.location.hash;
      if (hash === "#beta") {
        const element = document.getElementById("beta");
        if (element) {
          // Scroll to the element
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    checkHashAndScroll();

    // Optional: Listen for changes in the hash and scroll when it changes to #beta
    window.addEventListener("hashchange", checkHashAndScroll, false);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("hashchange", checkHashAndScroll, false);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="flex flex-col w-full min-h-screen bg-neutral-50">
      <Toolbar pricing />
      <Body />
      <LandingPageBottomBottom />
      <BetaUsersSection />
      <ArticleSection />
      <LandingPageFooter />
    </div>
  );
}

// Toolbar with logo, login, and register buttons

// Body with Header and subtext
function Body() {
  return (
    <div className="flex flex-col items-start justify-start mt-12 md:mt-16  md:ml-20 pb-20 text-neutral-700">
      <div className="w-full px-4 md:px-0 md:w-3/5">
        <div className="text-sm md:text-base text-center text-white md:px-2 w-12 md:w-14 rounded-xl bg-slate-600">
          beta
        </div>
        <div className="text-5xl max-w-7xl md:text-6xl font-extrabold">
          Always synced engineering documents.
        </div>
        <div className="flex-col items-center justify-start mt-6  ">
          <div className="flex flex-col space-y-4 text-2xl">
            <div className="flex items-center justify-start space-x-2 md:h-auto">
              <Check color={"green"} strokeWidth={3} size={20} className="w-10 mr-2" />
              Automatically generate interface control documents
            </div>
            <div className="flex items-center justify-start space-x-2 md:h-auto">
              <Check color={"green"} strokeWidth={3} size={20} className="w-10 mr-2" />
              Automatically generate design structure matrices
            </div>
            <div className="flex items-center justify-start space-x-2 md:h-auto">
              <Check color={"green"} strokeWidth={3} size={20} className="w-10 mr-2" />
              Change one doc, and they all update
            </div>
          </div>
        </div>
        <EnterpriseCallout />
        <ActionButtonsWaitlist />
      </div>
      <LandingPageVideoPlayer />
    </div>
  );
}

function EnterpriseCallout() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full mt-8">
      <div className="w-full py-6 p-4 rounded-lg text-black border border-green-600 bg-green-50 ">
        <div className="text-2xl font-bold mb-4">
          Tired of Spending $10k-$100k Per User on Outdated Software?
        </div>
        <div className="text-lg font-semibold text-gray-600">
          With Sinkly, we're eliminating the need for expensive software licenses, making top-tier
          design tools accessible to all. We're building powerful tools with seamless collaboration.
          All at a sensible price.
          <div className="mt-4">Join the waitlist to see for yourself.</div>
        </div>
      </div>
    </div>
  );
}

export function ActionButtonsWaitlist() {
  const navigate = useNavigate();

  // Function to handle navigation on button click
  function handleJoinWaitlist() {
    console.log("Get started clicked");
    navigate(CLIENT_ROUTE.WAITLIST);
  }
  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 my-8 items-center justify-start w-full h-16 text-xl">
      <button
        onClick={handleJoinWaitlist}
        className="px-10 py-4 mx-2 text-white bg-gradient-to-br from-indigo-500 to-indigo-600 rounded-xl" // Add Tailwind CSS classes as needed
      >
        Join waitlist
      </button>
    </div>
  );
}

export function ArticleSection() {
  return (
    <div className="flex flex-col items-center justify-center w-full pb-20 text-neutral-700 p-10">
      <div className="w-11/12 xl:w-2/3 h-auto p-6 lg:p-8 rounded-xl border border-green-500 bg-green-50">
        <div className="my-4 text-3xl lg:text-4xl font-extrabold text-neutral-700">
          Read what others have to say.
        </div>

        {/* Left side */}
        <div className="flex flex-col space-y-4 w-full lg:w-1/2 text-xl font-semibold rounded-xl ">
          <div className="flex justify-start items-center">
            <div>
              <Sparkle className="mr-2" size={32} color={`${Color.Indigo.css.sixHundred}`} />
            </div>
            <div>
              <a
                href="https://hackerpulse.substack.com/p/sinkly-keeping-your-system-engineering"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                "A tool for transforming clunky Excel processes into a seamless, collaborative, and
                automated experience"
              </a>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <div>
              <Sparkle className="mr-2" size={32} color={`${Color.Indigo.css.sixHundred}`} />
            </div>
            <div>
              <a
                href="https://hackerpulse.substack.com/p/sinkly-keeping-your-system-engineering"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                "It's the tech stack that positions Sinkly for continuous improvement, adaptability,
                and a user-centric experience"
              </a>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <div>
              <Sparkle className="mr-2" size={32} color={`${Color.Indigo.css.sixHundred}`} />
            </div>
            <div>
              <a
                href="https://hackerpulse.substack.com/p/sinkly-keeping-your-system-engineering"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                "Sinkly is a groundbreaking design tool tailored for mechanical designers and system
                engineers"
              </a>
            </div>
          </div>
        </div>

        <div className="text-3xl underline font-semibold mt-10 text-neutral-700 hover:text-neutral-400 cursor-pointer">
          <a
            href="https://hackerpulse.substack.com/p/sinkly-keeping-your-system-engineering"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center">
              Read for yourself
              <ArrowRight className="ml-2" size={32} color={`${Color.Neutral.css.sixHundred}`} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
export default LandingPageWaitList;
