import { useEffect, useRef, useState } from "react";
import { Copy } from "lucide-react";
import { useToast } from "../../ui/use-toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { ICDCell } from "src/models/ICDRow";
import { setSelectedCell } from "src/state/slices/icdSlice";
import { EdgeData } from "src/models/BoxDiagram/Edge";
import { Edge } from "reactflow";
import { updateRowData } from "src/state/reducers/icdReducers";

interface ICDResponsibilityCellProps {
  edge: Edge<EdgeData>;
  rowIndex: number;
  columnSize: number;
  colID: string;
  onChange?: (newValue: string) => void;
}

function ICDResponsibilityCell({
  rowIndex,
  colID,
  edge,
  columnSize,
  onChange,
}: ICDResponsibilityCellProps) {
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(edge.data.icd ? edge.data.icd.responsibility : "");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);

  const cell: ICDCell = { rowIndex, colID };
  const selectedCell = useSelector((state: RootState) => state.icd.selectedCell);

  // Focus the input when editing starts
  useEffect(() => {
    // Automatically adjust the height of the textarea
    if (textAreaRef.current) {
      textAreaRef.current.setSelectionRange(editValue.length, editValue.length);

      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }

    // Focus the input when user selects the cell
    if (isEditing) {
      textAreaRef.current?.focus();
    }

    // Automatically exit edit mode when clicking outside the cell
    function handleClickOutside(event: MouseEvent) {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        if (onChange) onChange(editValue);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, onChange]);

  // Show the input when user clicks. If user is already editing, do nothing.
  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    // No cells are selected
    if (!selectedCell) {
      dispatch(setSelectedCell({ cell }));
      setIsEditing(true);
      return;
    }

    // User clicked on new cell
    if (selectedCell.colID !== cell.colID && selectedCell.rowIndex !== cell.rowIndex) {
      dispatch(setSelectedCell({ cell: null }));
      return;
    }

    // User clicked on the same cell. Start editing.
    setIsEditing(true);
  }

  // Update the value and adjust height when the input changes
  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setEditValue(e.target.value);

    // Automatically adjust the height of the textarea
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset the height first
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }

    if (onChange) {
      onChange(editValue);
    }

    // Save changes in Redux
    dispatch(updateRowData({ edgeId: edge.id, data: { responsibility: editValue } }));
  }

  // Update the value and dismiss when Enter is pressed
  function handleOnKeydown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent newline when user hits enter
      setIsEditing(false);

      // Call the onChange callback
      if (onChange) {
        onChange(editValue);
      }
    }
  }

  return (
    <div
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      ref={cellRef}
      className="relative p-4 h-full text-lg"
      onClick={handleClick}
      style={{ width: columnSize }}
    >
      {isEditing && (
        <textarea
          ref={textAreaRef}
          className="absolute top-0 left-0 w-full z-10 bg-white p-2 border rounded-md shadow-md overflow-hidden"
          style={{ minHeight: "100%", resize: "none", width: columnSize + 40 }}
          value={editValue}
          onChange={handleChange}
          onKeyDown={handleOnKeydown}
        />
      )}

      <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{editValue}</div>

      {showButton && !isEditing && <FloatingCopyButton text={editValue} />}
    </div>
  );
}

/**
 * FloatingCopyButton - A button component that floats over content and is aligned to the right.
 */
export function FloatingCopyButton({ text }: { text: string }) {
  const { toast } = useToast();

  function copyToClipBoard(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    navigator.clipboard
      .writeText(text)
      .then(() => {
        showToast();
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  function showToast() {
    toast({
      title: "Copied to clipboard",
      className: "flex justify-center rounded-xl bg-gray-900 text-white w-48",
      duration: 2000,
    });
  }

  return (
    <button
      className="absolute flex items-center top-1 right-1 bg-white p-2 border rounded-lg shadow-md text-gray-600 hover:bg-gray-100"
      style={{ zIndex: 20 }}
      onClick={copyToClipBoard}
    >
      <Copy size={15} />
    </button>
  );
}

export default ICDResponsibilityCell;
