import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";

import { FloatingCopyButton } from "./ICDDescriptionCell";
import { setInterfaceIdPrefix, setNodeLabel } from "src/state/slices/documentSlice";
import { NodeData } from "src/models/BoxDiagram/Node";
import { Node as RFNode } from "reactflow";
import { getNodeById } from "src/state/selectors/boxDiagramSelectors";
import { setZoomToNode } from "src/state/slices/BoxDiagram/boxDiagramUISlice";
import { setSelectedSection } from "src/state/slices/sidebarSlice";
import { SideNavigationBarSection } from "src/state/slices/uiSlice";
import { applyNewNodeChanges } from "src/state/reducers/boxDiagramReducers";

interface ICDRowNumberCellProps {
  nodeID: string;
  columnSize: number;
  rowIndex: number;
  colID: string;
  onChange?: (newValue: string) => void;
}

function ICDRowNumberCell({
  rowIndex,
  columnSize,
  nodeID,
  colID,
  onChange,
}: ICDRowNumberCellProps) {
  return (
    <div className="flex justify-center items-start h-full w-8 pt-4 text-lg text-gray-500 ">
      {rowIndex + 1}
    </div>
  );
}
export default ICDRowNumberCell;
