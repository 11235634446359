import { ArrowRight, Check, Coffee, DraftingCompass, Sparkle, Sparkles } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { APPLICATION_NAME, CLIENT_ROUTE } from "src/utils/constants";
import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
import { ActionButtonsWaitlist, ArticleSection } from "./LandingPageWaitList";
const demoVideo = require("../../assets/videos/demo_v1.mov");
const overview_img_1 = require("../../assets/images/overview_img_1.png");
const overview_img_2 = require("../../assets/images/overview_img_2.png");
const overview_img_3 = require("../../assets/images/overview_img_3.png");

function LandingPage() {
  useEffect(() => {
    // Function to check the hash and perform the scroll
    const checkHashAndScroll = () => {
      const hash = window.location.hash;
      if (hash === "#beta") {
        const element = document.getElementById("beta");
        if (element) {
          // Scroll to the element
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    checkHashAndScroll();

    // Optional: Listen for changes in the hash and scroll when it changes to #beta
    window.addEventListener("hashchange", checkHashAndScroll, false);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("hashchange", checkHashAndScroll, false);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="flex flex-col w-full min-h-screen bg-neutral-50">
      <Toolbar pricing demo/>
      <Body />
      <ArticleSection />
      <LandingPageBottomBottom />
      <BetaUsersSection />
      <LandingPageFooter />
    </div>
  );
}

// Toolbar with logo, login, and register buttons
export function Toolbar({ pricing = false, demo = false }: { pricing?: boolean; demo?: boolean }) {
  const navigate = useNavigate();

  function handleNavHome() {
    navigate(CLIENT_ROUTE.HOME);
  }
  // Function to handle navigation on button click
  function handleGetStartedClick() {
    handleTryMeOutClick(navigate);
  }

  function navToPricing() {
    navigate(CLIENT_ROUTE.PRICING);
  }

  return (
    <div className="flex items-center justify-between w-full h-16 bg-neutral-800">
      <button
        onClick={handleNavHome}
        className="flex items-center justify-start pl-8 text-white text-2xl font-mono"
      >
        <Coffee size={24} className="mr-2" />
        {APPLICATION_NAME}
      </button>

      <div className="flex items-center pr-4 text-white rounded-md">
        {pricing && (
          <>
            <button onClick={navToPricing} className="pr-4 py-2 mx-2 space-x-2 text-white">
              Pricing
            </button>
            {pricing && demo && <div className="w-px h-6 bg-gray-300"></div>}
          </>
        )}
        {demo && (
          <button
            onClick={handleGetStartedClick}
            className="flex items-center pl-4 py-2 mx-2 space-x-2 text-white"
          >
            <div>Try the demo</div>
            <ArrowRight size={18} />
          </button>
        )}
      </div>
    </div>
  );
}

// Body with Header and subtext
function Body() {
  return (
    <div className="flex flex-col items-start justify-start mt-12 md:mt-16  md:ml-20 pb-10 text-neutral-700">
      <div className="w-full px-4 md:px-0 md:w-3/5">
        <div className="flex items-center space-x-2 text-sm md:text-base text-center text-white px-2 w-16 md:w-20 rounded-xl bg-neutral-700">
          <Sparkles size={16} />
          <div>beta</div>
        </div>
        <div className="text-5xl max-w-7xl md:text-6xl font-extrabold">
          Always synced engineering documents.
        </div>
        <div className="flex-col items-center justify-start mt-6  ">
          <div className="flex flex-col space-y-4 text-2xl">
            <div className="flex items-center justify-start space-x-2 md:h-auto">
              <Check color={"green"} strokeWidth={3} size={20} className="w-10 mr-2" />
              Automatically generate interface control documents
            </div>
            <div className="flex items-center justify-start space-x-2 md:h-auto">
              <Check color={"green"} strokeWidth={3} size={20} className="w-10 mr-2" />
              Automatically generate design structure matrices
            </div>
            <div className="flex items-center justify-start space-x-2 md:h-auto">
              <Check color={"green"} strokeWidth={3} size={20} className="w-10 mr-2" />
              Change one doc, and they all update
            </div>
          </div>
        </div>
        <ActionButtons />
      </div>
      <LandingPageVideoPlayer />
    </div>
  );
}

export function LandingPageVideoPlayer() {
  return (
    <div className="flex justify-start w-full h-full px-2 md:px-0">
      <video
        className="my-14 w-full max-w-7xl md:w-4/5 rounded-2xl shadow-2xl border border-gray-400" // Set the desired width or use Tailwind CSS classes as needed
        autoPlay
        loop
        muted
        playsInline // This is important for autoplay in some browsers
      >
        <source src={demoVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

function ActionButtons() {
  const navigate = useNavigate();

  // Function to handle navigation on button click
  function handleGetStartedClick() {
    console.log("Get started clicked");
    navigate(CLIENT_ROUTE.SIGN_IN);
  }
  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 my-8 items-center justify-start w-full h-16 text-xl">
      <button
        onClick={handleGetStartedClick}
        className="px-10 py-4 mx-2 text-white bg-gradient-to-br from-indigo-500 to-indigo-600 rounded-xl" // Add Tailwind CSS classes as needed
      >
        Get started for free
      </button>
      <button
        className="flex items-center justify-center py-4 space-x-2 text-black font-bold "
        onClick={() => {
          handleTryMeOutClick(navigate);
        }}
      >
        <div className="flex items-center justify-center h-full ">Try the demo</div>
        <ArrowRight size={20} />
      </button>
    </div>
  );
}

export function LandingPageBottomBottom() {
  const overViewCopy = [
    {
      image: overview_img_3,
      title: "Seamless synchronization with every edit.",
      description:
        "When you update any document, Sinkly automatically updates all other documents that reference it. No more manual updates.",
    },
    {
      image: overview_img_1,
      title: "Generate all docs from one drawing.",
      description:
        "You create one block diagram, and Sinkly will make your Interface Control Document and Design Structure Matrix. No more copy-pasting.",
    },
    {
      image: overview_img_2,
      title: "Share and collaborate with anyone.",
      description:
        "Share your documents with anyone. They can view or edit your documents without needing an account.",
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center  bg-neutral-300 py-10 pb-16">
      <div className="text-4xl md:text-5xl font-extrabold my-1 text-neutral-700">
        Your productivity hack
      </div>
      <div className="flex flex-col md:flex-row md:w-full lg:w-3/4 items-center justify-center space-y-10 sm:space-y-0 sm:space-x-10 px-4 md:px-8 lg:px-10 xl:px-0 my-10">
        {overViewCopy.map((copy, index) => (
          <OverViewImage
            key={index}
            title={copy.title}
            description={copy.description}
            image={copy.image}
          />
        ))}
      </div>
    </div>
  );
}

function OverViewImage({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: string;
}) {
  return (
    <div className="flex flex-col items-center justify-between h-md max-w-screen-sm w-4/6  ">
      <img src={image} className="block pb-2 sm:w-60 lg:w-80" alt="overview" />

      <div className="flex flex-col items-start justify-center text-neutral-800 w-full">
        <div className="h-px my-1 w-full bg-black"></div>
        <div className="h-24 text-3xl font-bold w-full text-center">{title}</div>
        <div className="mt-6 text-base md:text-lg w-full  text-center">{description}</div>
      </div>
    </div>
  );
}

export function BetaUsersSection() {
  return (
    <div className="flex flex-col items-center justify-center w-full lg:p-10 bg-neutral-50">
      <div className="w-11/12 xl:w-2/3 h-auto p-4 lg:p-8 m-8 rounded-xl border border-yellow-500 bg-yellow-50">
        <div className="flex items-center justify-center space-x-2 p-1 text-lg text-center text-white md:px-2 w-40 md:w-40 rounded-xl bg-neutral-700">
          <Sparkles size={16} />
          <span id="beta">beta offer only</span>
        </div>

        <div className="mt-4 text-3xl lg:text-4xl font-extrabold text-neutral-700">
          <span>{"Use Sinkly for "}</span>
          <span className="underline">free</span>
          <span> in your team.</span>
        </div>
        <div className="text-xl my-4 font-semibold text-neutral-600">
          <span className="font-extrabold">{"You"}</span>
          <span>{" want a great product. "}</span>
          <span className="font-extrabold">{"We"}</span>
          <span>{" want to make it. "}</span>
          <span className="font-extrabold">{"Everyone"}</span>
          <span>{" loves free. "}</span>
          <span>{"Let us know how you use Sinkly for free access."}</span>
        </div>

        <div className="flex text-xl text-neutral-700">
          {/* Left side */}
          <div className="flex flex-col space-y-4 w-1/2 font-semibold rounded-xl ">
            <div className="flex justify-start items-center">
              <div>
                <Check className="mr-2" size={32} color="green" />
              </div>
              <div>Free access Sinkly post-beta</div>
            </div>

            <div className="flex justify-start items-center">
              <div>
                <Check className="mr-2" size={32} color="green" />
              </div>
              <div>Early access to features</div>
            </div>

            <div className="flex justify-start items-center">
              <div>
                <Check className="mr-2" size={32} color="green" />
              </div>
              <div>Direct line to the development team</div>
            </div>
          </div>

          <div className="flex flex-col space-y-4 w-1/2 font-semibold rounded-xl ">
            <div className="flex justify-start items-center">
              <div>
                <Check className="mr-2" size={32} color="green" />
              </div>
              <div>Create unlimited docs</div>
            </div>

            <div className="flex justify-start items-center">
              <div>
                <Check className="mr-2" size={32} color="green" />
              </div>
              <div>Priority support</div>
            </div>

            <div className="flex justify-start items-center">
              <div>
                <Check className="mr-2" size={32} color="green" />
              </div>
              <div>Currently accepting new users</div>
            </div>
          </div>
        </div>

        <div className="text-xl font-semibold mt-10 text-neutral-700">
          {"Select a slot below. It'll be fun. Promise."}
        </div>
        <Calendar />
      </div>

      <div className="flex flex-col items-center justify-center w-full h-full p-8">
        <div className="text-4xl font-extrabold text-neutral-700">
          <span>{"Ready to "}</span>
          <span className="text-gray-400 line-through">{" try Sinkly"}</span>
          <span className="">{" save hours of your time?"}</span>
        </div>
        <div className="flex">
          <ActionButtons />
        </div>
      </div>
    </div>
  );
}

export function LandingPageFooter() {
  return (
    <div className="flex items-center justify-center w-full py-8 bg-neutral-800">
      <div className="flex items-center justify-center space-x-4 h-auto text-white">
        <Coffee size={24} />
        <div>© 2024 {APPLICATION_NAME}</div>
        <Sparkle size={24} />
      </div>
    </div>
  );
}

export function handleTryMeOutClick(navigate: any) {
  navigate("/documents/r22c89c3-e114-461e-b055-bd43ccb6f2b8");
}

function Calendar() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        cssVarsPerTheme: {
          light: {
            "brand-color": "#000000",
          },
          dark: {
            "brand-color": "#000000",
          },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);
  return (
    <Cal
      // className="rounded-xl"
      calLink="darrelmuonekwu/30min"
      style={{
        width: "100%",
        maxHeight: 900,
        height: "100%",
        overflow: "scroll",
        marginTop: "20px",
      }}
      config={{ layout: "month_view" }}
    />
  );
}

export default LandingPage;
