// src/components/ICD/Columns/ICDTableCell.tsx
import { useEffect, useRef, useState } from "react";
import { Copy, GripVertical, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { SubConnection } from "src/models/Connection";
import { FloatingCopyButton } from "./ICDDescriptionCell";
import { setConnections } from "src/state/slices/documentSlice";
import { Edge } from "reactflow";
import { EdgeData } from "src/models/BoxDiagram/Edge";

interface ICDConnectionCellProps {
  edge: Edge<EdgeData>;
  columnSize: number;
  rowIndex: number;
  colID: string;
  onChange?: (newValue: string) => void;
}

function ICDConnectionCell({
  rowIndex,
  colID,
  columnSize,
  edge,
  onChange,
}: ICDConnectionCellProps) {
  const [showButton, setShowButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const cellRef = useRef<HTMLDivElement>(null);

  // Focus the input when editing starts
  useEffect(() => {
    // Automatically exit edit mode when clicking outside the cell
    function handleClickOutside(event: MouseEvent) {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        // if (onChange) onChange(editValue);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, onChange]);

  function formatTextForCopy(legendItems: SubConnection[]) {
    const itemsWithoutTrailingComma = legendItems.map((item) => item.label).join(", ");
    return itemsWithoutTrailingComma;
  }

  function handleOnClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  return (
    <div
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      ref={cellRef}
      className="relative py-3 h-full text-lg"
      onClick={handleOnClick}
      style={{ width: columnSize }}
    >
      {showButton && !isEditing && (
        <FloatingCopyButton text={formatTextForCopy(edge.data.connections)} />
      )}
      {isEditing && <ConnectionPicker edge={edge} />}
      <div className="cursor-pointer">
        <DisplayedConnections legendItems={edge.data.connections} />
      </div>
    </div>
  );
}

function DisplayedConnections({ legendItems }: { legendItems: SubConnection[] }) {
  return (
    <div className="flex flex-wrap pt-2 pr-2 justify-start ">
      {legendItems.map((item, index) => (
        <div className="mb-2 pl-4" key={item.id}>
          <ConnectionItem unselectedItem={item} />
        </div>
      ))}
    </div>
  );
}

/**
 * ConnectionPicker - A component that allows users to reorder a list of items using drag and drop.
 */
interface ConnectionPickerProps {
  edge: Edge<EdgeData>;
  allowDeleteLastConn?: boolean;
}
export function ConnectionPicker({ edge, allowDeleteLastConn = true }: ConnectionPickerProps) {
  const dispatch = useDispatch();
  const allConnections = useSelector(
    (state: RootState) => state.document.documentContainer.connections
  );

  const [selectedItems, setSelectedItems] = useState<SubConnection[]>(edge.data.connections);
  // BUG 🐞: If user has connection with the same name, this will not work.
  // FIX: Use the ID instead of the label and save LegendItem in DB so we don't create
  // new LegendItems with separate IDs each time we load the document.
  const [unselectedItems, setUnselectedItems] = useState<SubConnection[]>(
    allConnections.filter(
      // Remove items that are already selected
      (item) => !selectedItems.some((connection) => connection.label === item.label)
    )
  );

  // Move item from selected to unselected
  function deselectItem(item: SubConnection) {
    if (selectedItems.length === 1 && !allowDeleteLastConn) return;

    setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));
    setUnselectedItems((prev) => [...prev, item]);

    const newItems = selectedItems.filter((i) => i.id !== item.id);
    dispatch(setConnections({ source: edge.source, target: edge.target, connections: newItems }));
  }

  // Move item from unselected to selected
  function selectItem(item: SubConnection) {
    if (selectedItems.length === 1 && selectedItems[0].label === "Default") {
      setSelectedItems([item]);
      setUnselectedItems(allConnections.filter((i) => i.id !== item.id));

      dispatch(
        setConnections({
          source: edge.source,
          target: edge.target,
          connections: [item],
        })
      );
      return;
    }

    setSelectedItems((prev) => [...prev, item]);
    setUnselectedItems((prev) => prev.filter((i) => i.id !== item.id));
    dispatch(
      setConnections({
        source: edge.source,
        target: edge.target,
        connections: [...selectedItems, item],
      })
    );
  }

  /**
   * Handles the end of a drag event and updates the items' order accordingly.
   * @param {DropResult} result - The result object provided by react-beautiful-dnd.
   */
  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = Array.from(unselectedItems);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);
    setUnselectedItems(updatedItems);
  };

  return (
    <div className="absolute top-0 left-0 w-96 rounded-lg shadow-lg bg-white border pb-4 z-50">
      <SelectedConnections selectedItems={selectedItems} removeItem={deselectItem} />
      <HorizontalDivider />
      <SelectOptionTitle />

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: "50px" }}>
              {unselectedItems.map((unselectedItem, index) => (
                <DraggableItem
                  key={unselectedItem.id}
                  unselectedItem={unselectedItem}
                  index={index}
                  addItem={selectItem}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
interface SelectedConnectionsProps {
  selectedItems: SubConnection[];
  removeItem: (item: SubConnection) => void;
}
function SelectedConnections({ selectedItems, removeItem }: SelectedConnectionsProps) {
  return (
    <div
      style={{ minHeight: "50px" }}
      className="flex flex-wrap pt-2 pl-4 justify-start  bg-neutral-50"
    >
      {selectedItems.map((item) => (
        <ConnectionItemX key={item.id} item={item} removeItem={removeItem} />
      ))}
    </div>
  );
}

interface DraggableItemProps {
  unselectedItem: SubConnection;
  index: number;
  addItem: (item: SubConnection) => void;
}

function DraggableItem({ unselectedItem, index, addItem }: DraggableItemProps) {
  return (
    <Draggable draggableId={unselectedItem.id} index={index}>
      {(provided) => (
        <div
          onClick={() => addItem(unselectedItem)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="flex items-center h-10 hover:bg-gray-100 rounded-md pl-4"
        >
          <ConnectionItem unselectedItem={unselectedItem} />
        </div>
      )}
    </Draggable>
  );
}

function ConnectionItemX({
  item,
  removeItem,
}: {
  item: SubConnection;
  removeItem: (item: SubConnection) => void;
}) {
  const backgroundColor = item.color.tailwind.oneHundred;
  function handleOnClick() {
    removeItem(item);
  }
  return (
    <button onClick={handleOnClick}>
      <div className={`flex items-center px-2 mb-2 mr-2 h-8 space-x-2 ${backgroundColor} rounded`}>
        <div>{item.label}</div>
        <X size={20} color="gray" />
      </div>
    </button>
  );
}

interface ConnectionItemGripProps {
  unselectedItem: SubConnection;
}
export function ConnectionItem({ unselectedItem }: ConnectionItemGripProps) {
  const backgroundColor = unselectedItem.color.tailwind.oneHundred;
  return (


      <div className={`flex items-center px-3 py-1 ${backgroundColor} rounded`}>
        {unselectedItem.label}
      </div>

  );
}

function HorizontalDivider() {
  return <div className="border-b mb-2 " />;
}

function SelectOptionTitle() {
  return <span className="text-neutral-500 pl-4 text-sm font-medium">Select an option to add</span>;
}

export default ICDConnectionCell;
