import { ArrowRight, Check, Coffee, Headphones, Sparkles, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APPLICATION_NAME, CLIENT_ROUTE } from "src/utils/constants";
import { Toolbar, handleTryMeOutClick } from "./LandingPage";
import { createCheckoutSession } from "src/api/service/stripeService";
import { useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { getCalApi } from "@calcom/embed-react";
import { isSignedIn } from "src/utils/helpers";
import { StripeProductType, Subscription } from "src/models/Subscription";
// import { FcInfo } from "react-icons/fc";
// // motion
// import { motion } from "framer-motion";
// // variants
// import { fadeIn } from "../variants";

const packages = [
  {
    name: "Individual",
    monthlyPrice: 25,
    yearlyPrice: 250,
    numBlocks: "Unlimited",
    numSeats: "1 seat",
    sso: null,
    versionControl: null,
    support: "Priority email support",
    documentsPerUser: "Unlimited docs /user",
    perUserText: "/month",
    perUserTextAnnual: "annually",
    productType: StripeProductType.INDIVIDUAL,
    description:
      "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Team",
    monthlyPrice: 65,
    yearlyPrice: 650,
    numBlocks: "Unlimited",
    numSeats: "15 seats",
    sso: null,
    versionControl: "Prioritized support",
    support: "Up to 1 hour of individual support via email or video per month",
    documentsPerUser: "Unlimited docs /user",
    perUserText: "/user/month",
    perUserTextAnnual: "/user/annually",
    productType: StripeProductType.ORGANIZATION,
    description:
      "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Enterprise",
    monthlyPrice: 120,
    yearlyPrice: 1200,
    numBlocks: "Unlimited",
    numSeats: "Unlimited seats",
    sso: null,
    versionControl: "Version control",
    support: "Up to 1 hour of individual support via email or video per month",
    documentsPerUser: "Unlimited docs /user",
    perUserText: ".",
    perUserTextAnnual: ".",
    productType: StripeProductType.ENTERPRISE,
    description:
      "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  // {
  //   name: "Hobby",
  //   monthlyPrice: 0,
  //   yearlyPrice: 0,
  //   numBlocks: "40",
  //   numSeats: "1 seat",
  //   sso: null,
  //   versionControl: null,
  //   support: "Email support",
  //   documentsPerUser: "2 docs /user",
  //   perUserText: "Free",
  //   perUserTextAnnual: "Still free",
  //   productType: StripeProductType.HOBBY,
  //   description:
  //     "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  // },
];

function PricingPage() {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <div>
      <Toolbar demo />
      {/* <button data-cal-link="darrelmuonekwu/30min" onClick={handleShowCal}>
        click me
      </button> */}
      <div className="py-10 md:px-14 p-4 max-w-screen-2xl mx-auto">
        <div className="text-center">
          <Header />
          <PriceToggle isYearly={isYearly} setIsYearly={setIsYearly} />
          <BetaOnlyTag />
          <PricingCards isYearly={isYearly} />
        </div>
      </div>
    </div>
  );
}

function Header() {
  return (
    <div>
      <span className="md:text-6xl text-3xl font-semibold text-gray-400 mb-2 line-through">
        Our pricing
      </span>
      <span className="md:text-6xl text-3xl font-semibold text-neutral-800 mb-2">
        {" Your productivity hack"}
      </span>
      <p className="mt-8 text-xl md:w-2/3 mx-auto">
        No more manually entering the same information into different docs, just a little investment
        to 10X your productivity.
      </p>
    </div>
  );
}

function PricingCards({ isYearly }: { isYearly: boolean }) {
  const navigate = useNavigate();
  const [signedIn, setSignedIn] = useState(false);

  const user = useSelector((state: RootState) => state.user.user);
  const subscription = useSelector((state: RootState) => state.user.subscription);
  const firebaseUser = useSelector((state: RootState) => state.user.firebaseUser);
  const optionalEmail: string | null = user ? user.email : null;

  useEffect(() => {
    setSignedIn(isSignedIn(firebaseUser, user));
    // Need to call this function to initialize the cal.com widget
    // The 2nd time this is called, it will show the cal.com widget
    handleShowCal();
  }, []);

  function handleCheckoutClick() {
    createCheckoutSession(optionalEmail, isYearly)
      .then((checkoutUrl) => {
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        console.error("Failed to redirect to checkout page", error);
        alert("Failed to redirect to checkout. Please try again.");
      });
  }

  function navigateTo(route: string) {
    navigate(route);
  }

  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-10  mx-auto">
      {packages.map((pkg, index) => (
        <div
          key={index}
          className="py-10 md:px-6 px-4 rounded-lg shadow-3xl text-neutral-800 border-2 border-neutral-500 "
        >
          <h3 className="text-3xl  font-bold text-center ">{pkg.name}</h3>
          {/* <p className="text-tertiary text-center my-6">{pkg.description}</p> */}
          <div className="mt-5 text-center text-secondary text-4xl font-bold">
            {pkg.name === "Enterprise" || pkg.name === "Team" ? (
              <div className="text-3xl">Contact us</div>
            ) : isYearly ? (
              <>
                {pkg.name === "Individual" && (
                  <>
                    <span className="line-through text-gray-500">${pkg.yearlyPrice}</span>
                    <span className=""> ${10}</span>
                  </>
                )}
                {pkg.name === "Hobby" && <span className="">${pkg.yearlyPrice}</span>}
              </>
            ) : (
              <>
                {pkg.name === "Individual" && (
                  <>
                    <span className="line-through text-gray-500">${pkg.monthlyPrice}</span>
                    <span className=""> ${1}</span>
                  </>
                )}
                {pkg.name === "Hobby" && <span className="">${pkg.monthlyPrice}</span>}
              </>
            )}
            <div className="mt-2 text-lg text-neutral-500 font-normal">
              {isYearly ? pkg.perUserTextAnnual : pkg.perUserText}
            </div>
          </div>
          <ul className="mt-4 space-y-3 font-medium">
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              Block diagram editor
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              ICD editor
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              DSM editor
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              {pkg.numBlocks} blocks /document
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              {pkg.documentsPerUser}
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              {pkg.numSeats}
            </li>
            <li className="flex items-center">
              {pkg.versionControl ? (
                <Check className="mr-2 text-xl" color="green" />
              ) : (
                <X className="mr-2 text-xl" color="gray" />
              )}
              Version control
            </li>
            <li className="flex items-start justify-start ">
              <div>
                {pkg.name !== "Hobby" ? (
                  <Headphones className="mr-2 text-xl" color="green" />
                ) : (
                  <Headphones className="mr-2 text-xl" color="gray" />
                )}
              </div>
              <div className="flex text-start items-start justify-start">{pkg.support}</div>
            </li>
          </ul>

          {getActionBtn(pkg.productType, signedIn, subscription, handleCheckoutClick, navigateTo)}
=        </div>
      ))}
    </div>
  );
}

function BetaOnlyTag() {
  return (
    <div className="flex items-center justify-center space-x-2 p-2 mb-2 text-center text-white md:px-2 w-56 rounded-full bg-indigo-700">
      <Sparkles size={18} />
      <span>Pricing during beta</span>
    </div>
  );
}

function getActionBtn(
  productType: StripeProductType,
  signedIn: boolean,
  subscription: Subscription | null,
  handleCheckoutClick: () => void,
  navigateTo: (route: string) => void
) {
  // Define the action to be taken based on the subscription status
  let action = null;
  let buttonLabel = "";
  if (productType === StripeProductType.HOBBY) {
    buttonLabel = "Continue home";
    action = () => {
      navigateTo(CLIENT_ROUTE.DOCUMENT_HOME);
    };
  } else if (productType === StripeProductType.INDIVIDUAL) {
    buttonLabel = "Get started";
    action = handleCheckoutClick;
  } else {
    buttonLabel = "Contact us";
    action = async () => {
      await handleShowCal();
    };
  }

  // If user clicks on the subscription they already have, navigate to the document home
  if (subscription && subscription.product_type === productType) {
    buttonLabel = "Current plan";
    action = () => {
      navigateTo(CLIENT_ROUTE.DOCUMENT_HOME);
    };
  }

  // If user is not signed in, navigate to the sign in page
  if (
    !signedIn &&
    subscription === null &&
    productType !== StripeProductType.ENTERPRISE &&
    productType !== StripeProductType.ORGANIZATION
  ) {
    action = () => {
      navigateTo(CLIENT_ROUTE.SIGN_IN);
    };
  }

  console.log(subscription);
  if (
    productType === StripeProductType.ENTERPRISE ||
    productType === StripeProductType.ORGANIZATION
  ) {
    return (
      <div className="flex flex-col w-full mx-auto items-center justify-center mt-5">
        <button
          data-cal-link="darrelmuonekwu/30min"
          onClick={action}
          className="mt-6 px-8 py-3 text-xl bg-neutral-700 text-white border border-neutral-300   hover:bg-neutral-200 hover:text-neutral-800 font-semibold rounded-lg"
        >
          {buttonLabel}
        </button>
        <div className="text-xs text-neutral-500 mt-2">We will get back to you within 24 hours</div>
      </div>
    );
  } else {
    return (
      <div className="w-full mx-auto flex items-center justify-center mt-5">
        <button
          onClick={action}
          className="mt-6 px-8 py-3 text-xl bg-neutral-700 text-white border border-neutral-300   hover:bg-neutral-200 hover:text-neutral-800 font-semibold rounded-lg"
        >
          {buttonLabel}
        </button>
      </div>
    );
  }
}

async function handleShowCal() {
  const cal = await getCalApi();

  cal("ui", {
    theme: "auto",
    styles: {
      branding: { brandColor: "#000000" },
    },
  });
}

interface PriceToggleProps {
  isYearly: boolean;
  setIsYearly: (isYearly: boolean) => void;
}
function PriceToggle({ isYearly, setIsYearly }: PriceToggleProps) {
  function setMonthly() {
    setIsYearly(false);
  }

  function setYearly() {
    setIsYearly(true);
  }

  const selectedStyle = "p-2 px-6 rounded-full text-neutral-100 bg-neutral-700";
  const unselectedStyle = "p-2 px-6 rounded-full text-neutral-500";
  return (
    <div className="flex items-center justify-center w-full mt-12 mb-10">
      <div className="flex items-center justify-between rounded-full border-2 border-neutral-400">
        <div className="flex space-x-1 p-1">
          <button onClick={setMonthly} className={isYearly ? unselectedStyle : selectedStyle}>
            Monthly
          </button>
          <button onClick={setYearly} className={isYearly ? selectedStyle : unselectedStyle}>
            Annually
          </button>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
