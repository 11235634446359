// src/components/DocumentCard.tsx

import { MoreVertical } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteDocumentAPI } from "src/api/service/documentService";
import { DocumentContainer } from "src/models/Document";
import { RootState } from "src/state/slices";
import { deleteDocument } from "src/state/slices/userSlice";
import { StatusCode } from "src/utils/Api";
import { saveDocToApiWithDebounce } from "src/utils/autoSave";
import { CLIENT_ROUTE } from "src/utils/constants";
import { timeAgoSting } from "src/utils/helpers";
import { DocumentCardContextMenu } from "./DocumentCardContextMenu";

interface DocumentCardProps {
  documentObj: DocumentContainer;
}

// Updated DocumentCard to allow it to shrink as needed with `min-w-0` and `w-full` within a max-width
function DocumentCard({ documentObj }: DocumentCardProps) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  // New state for controlling the visibility of the modal and dialog
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  // Function to open the

  const cardRef = useRef<HTMLDivElement>(null); // Ref to keep track of the card element

  // Function to toggle the context menu
  function toggleContextMenu(e?: React.MouseEvent) {
    // Sometimes we call this function with an event, sometimes we don't
    if (e) e.stopPropagation();

    setContextMenuOpen(!contextMenuOpen);

    // If the card is selected, unselect it. Better UX
    if (selected) {
      setSelected(false);
    }
  }

  // Close the context menu when clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setSelected(false);
        setContextMenuOpen(false); // Close the context menu
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Select the card or navigate to the document
  function handleCardClick() {
    if (selected) {
      // If already selected, navigate to the document
      navigate(CLIENT_ROUTE.DOCUMENT(documentObj.id));
    } else {
      // Select the card
      setSelected(true);
    }
  }

  // Function to handle the right click
  function onContextMenu(e: React.MouseEvent) {
    e.preventDefault();
    toggleContextMenu();
  }

  // Handlers for opening and closing modals and dialogs
  function handleOpenDeleteModal() {
    setContextMenuOpen(false);
    setIsDeleteModalOpen(true);
  }
  function handleOpenRenameDialog() {
    setContextMenuOpen(false);
    setIsRenameDialogOpen(true);
  }

  // Function to close the rename dialog
  function closeRenameDialog() {
    setIsRenameDialogOpen(false);
  }

  // Function to handle the delete action
  function onCloseDelete() {
    // Logic to delete the document
    // Dispatch an action or call an API
    setIsDeleteModalOpen(false);
  }

  return (
    <div
      ref={cardRef}
      onContextMenu={onContextMenu}
      onClick={handleCardClick}
      className={`relative flex flex-col items-center justify-center p-4 min-w-0 w-full max-w-lg h-40 bg-white rounded-lg shadow-md border-2 ${
        selected ? "border-indigo-500 bg-indigo-50" : "border-white hover:bg-slate-50"
      }`}
    >
      <button
        onClick={toggleContextMenu}
        className="absolute top-0 right-0 mt-2 mr-2 p-2 rounded-full hover:bg-gray-200"
      >
        <MoreVertical className="h-5 w-5" />
      </button>
      <DocumentCardContextMenu
        isOpen={contextMenuOpen}
        onClose={() => setContextMenuOpen(false)}
        documentObj={documentObj}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenRenameDialog={handleOpenRenameDialog}
      />
      <div className="mt-2 mb-4 font-semibold text-lg">{documentObj.name}</div>
      <div className="text-sm text-neutral-500">
        Edited {timeAgoSting(documentObj.updated_at_ms)}
      </div>
      {/* Conditionally render modals and dialogs */}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal docID={documentObj.id} onClose={onCloseDelete} />
      )}
      {isRenameDialogOpen && (
        <RenameDialog documentId={documentObj.id} onClose={closeRenameDialog} />
      )}
    </div>
  );
}

// This function will render the backdrop and the modal
function DeleteConfirmationModal({ docID, onClose }: { docID: string; onClose: () => void }) {
  const dispatch = useDispatch();

  function handleBackgroundClick(e: React.MouseEvent) {
    e.stopPropagation();
    onClose();
  }

  // Function to close the delete confirmation modal
  function onDeleteDocument() {
    // Delete from the server
    deleteDocumentAPI(docID)
      .then((resp) => {
        if (resp.status == StatusCode.HTTP_200) {
          // Delete from the redux store
          dispatch(deleteDocument({ id: docID }));
          onClose();
        } else {
          alert("Could not delete document. Please try again.");
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Could not delete document. Please try again.");
      });

    onClose();
  }

  return (
    <div onClick={handleBackgroundClick}>
      <div className="fixed inset-0 bg-black bg-opacity-10 z-40"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div onClick={(e) => e.stopPropagation()} className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-medium mb-4">Confirm Deletion</h3>
          <p>Are you sure you want to delete this document?</p>
          <div className="flex justify-end mt-8">
            <button
              className="bg-gray-200 hover:bg-gray-300 rounded-md px-8 py-2 mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="bg-black hover:bg-gray-800 text-white rounded-md px-8 py-2"
              onClick={onDeleteDocument}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// This function will render the dialog for renaming
function RenameDialog({ documentId, onClose }: { documentId: string; onClose: () => void }) {
  const document = useSelector((state: RootState) => {
    return state.user.documents.find((doc) => doc.id == documentId);
  });
  const [newName, setNewName] = useState(document.name);

  function handleBackgroundClick(e: React.MouseEvent) {
    e.stopPropagation();
    onClose();
  }

  async function onRenameDocument() {
    if (!newName.trim()) {
      alert("Please enter a name.");
      return;
    }

    try {
      await saveDocToApiWithDebounce(
        {
          id: documentId,
          owner_id: document.owner_id,
          name: newName,
          permissions: document.permissions,
        },
        0
      );

      onClose();
    } catch (err) {
      console.error(err);
      alert("Could not rename document. Please try again.");
    }
  }

  return (
    <div onClick={handleBackgroundClick}>
      <div className="fixed inset-0 bg-black bg-opacity-10 z-40"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div onClick={(e) => e.stopPropagation()} className="bg-white w-96 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-medium mb-4">Rename Document</h3>
          <input
            type="text"
            className="border rounded-lg px-4 py-2 w-full"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <div className="flex justify-end mt-4">
            <button
              className="bg-gray-200 hover:bg-gray-300 rounded-md px-8 py-2 mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="bg-black hover:bg-gray-800 text-white rounded-md px-8 py-2"
              onClick={onRenameDocument}
            >
              Rename
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentCard;
