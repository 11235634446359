import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "src/state/slices";
import { CLIENT_ROUTE } from "src/utils/constants";
import { auth } from "src/utils/firebaseUtils";

/**
 * This component is used to protect routes that require authentication.
 * If the user is not logged in, they will be redirected to the sign in page.
 */
function ProtectedRoutes() {
  const currentUser = useSelector((state: RootState) => state.user.firebaseUser);

  const [loading, setLoading] = useState(true);

  // Check if user is logged in
  useEffect(() => {
    console.log("Checking auth state1");
    setLoading(false);
  }, []);

  // Must show an empty screen while checking auth state. If you don't, the
  // user will be redirected to the sign in page while the app is checking
  if (loading) {
    return <div />;
  }

  return currentUser ? <Outlet /> : <Navigate to={CLIENT_ROUTE.SIGN_IN} />;
}

export default ProtectedRoutes;
