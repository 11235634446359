// src/components/BlockDiagram/Hooks/useIsMobile.ts

import { useEffect, useState } from "react";

export function useIsMobile() {
  // State to control the visibility of the popup
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen width
    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth;
      // Define the maximum width you consider to be mobile-sized
      if (screenWidth <= 572) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Check on mount and subsequent resize events
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup listener to prevent memory leaks
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return { isMobile };
}
