import { Coffee, Sparkles } from "lucide-react";
import { useState, useEffect } from "react";
import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  ActionCodeSettings,
  User as FirebaseUser,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useToast } from "../ui/use-toast";
import { Toaster } from "../ui/toaster";
import { validate } from "react-email-validator";
import { User, createUserFromFirebaseUser } from "src/models/User";
import { createUserAPI } from "src/api/service/userService";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { GetUserResponse, StatusCode } from "../../utils/Api";
import { RootState } from "src/state/slices";
import { CLIENT_ROUTE, CLIENT_URL } from "src/utils/constants";
import { setFirebaseUser, setUser } from "src/state/slices/userSlice";
import { auth } from "../../utils/firebaseUtils";
import { isSignedIn } from "src/utils/helpers";

const googleIcon = require("../../assets/icons/google.png");

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);
  const firebaseUser = useSelector((state: RootState) => state.user.firebaseUser);

  useEffect(() => {
    console.log("Server user is 1:", user);
    console.log("Server user is 2:", firebaseUser);
    if (isSignedIn(firebaseUser, user)) {
      navigate(CLIENT_ROUTE.DOCUMENT_HOME);
      return;
    }
    // For some reason, the user is not logged in but the auth.currentUser is not null
    else if ((auth.currentUser && !user) || (auth.currentUser && !user)) {
      console.log("CRITICAL ERROR🚨: User is not logged in but auth.currentUser is not null");
      // Clear the auth.currentUser
      auth.signOut();
      dispatch(setUser({ user: null }));
      dispatch(setFirebaseUser({ firebaseUser: null }));
      return;
    }
  }, [auth, user, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-orange-50">
      <SignInContent />
    </div>
  );
}

function SignInContent() {
  return (
    <>
      <Icon />
      <SignInCard />
      <Toaster />
    </>
  );
}

function Icon() {
  const navigate = useNavigate();
  function handleIconClick() {
    navigate(CLIENT_ROUTE.HOME);
  }
  return (
    <button onClick={handleIconClick} className="flex items-center justify-start w-96 mb-4 ">
      <Coffee size={32} />
    </button>
  );
}
// SignInCard component that contains the sign-in form and social buttons
function SignInCard() {
  return (
    <div className="flex flex-col items-center w-96 p-10 border border-orange-100 rounded-lg bg-stone-50 shadow-md">
      <SignInForm />
      <OrSeparator />
      <SignInButtonGoogle />
    </div>
  );
}

// SignInForm component for email sign-in
function SignInForm() {
  const [email, setEmail] = useState("");

  return (
    <div className="flex flex-col items-center w-full">
      <EmailHeader />
      <EmailInput setEmail={setEmail} />
      <SignInButton email={email} />
    </div>
  );
}

// EmailHeader component for the header text
function EmailHeader() {
  return (
    <div className="flex justify-start w-full mb-6 text-lg font-semibold">Sign in with email</div>
  );
}

// EmailInput component for the email input field
function EmailInput({ setEmail }: { setEmail: (email: string) => void }) {
  return (
    <input
      onChange={(e) => setEmail(e.target.value)}
      className="w-full p-2 mb-4 border border-gray-300 rounded"
      type="email"
      placeholder="email@example.com"
      required
    />
  );
}

// SignInButton component for the sign-in button
function SignInButton({ email }: { email: string }) {
  const { toast } = useToast();
  const navigate = useNavigate();

  function handleSignIn() {
    if (email.toLocaleLowerCase().includes("gmail")) {
      showToast("Please sign in with Google");
      return;
    }
    if (!validate(email)) {
      showToast("Please enter a valid email.");
      return;
    }

    signInWithEmailLink(email);
    navigate(CLIENT_ROUTE.VERIFY_EMAIL);
  }

  function showToast(title: string) {
    toast({
      title: title,
      className: "flex justify-center rounded-xl text-4xl bg-gray-900 text-white py-8 w-64 mb-20",
      duration: 5000,
    });
  }
  return (
    <button
      onClick={handleSignIn}
      className="w-full p-2  text-white  bg-indigo-600 rounded hover:bg-indigo-600"
      type="submit"
    >
      Sign in
    </button>
  );
}

// OrSeparator component to visually separate the sign-in methods
function OrSeparator() {
  return (
    <div className="flex items-center justify-center w-full my-4">
      <HorizontalDivider />
      <span className="px-2 bg-transparent text-gray-500">or</span>
      <HorizontalDivider />
    </div>
  );
}

function HorizontalDivider() {
  return <div className="w-full h-px bg-gray-300"></div>;
}

// SignInButtonGoogle component for each social button
function SignInButtonGoogle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleSignInGoogle() {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const firebaseUser = result.user;
        const res = await createUserInAPI(firebaseUser, dispatch);

        if (res && res.status === StatusCode.HTTP_200) {
          navigate(CLIENT_ROUTE.DOCUMENT_HOME);
          return;
        }
      })
      .catch((error) => {
        // Handle Errors here.
        alert("Error signing in. Please try again.");
      });
  }

  return (
    <button
      onClick={handleSignInGoogle}
      className="flex justify-between items-center w-full p-2 px-3 mb-2 text-left text-lg bg-white border border-gray-300 rounded hover:bg-gray-50"
      type="button"
    >
      <div>Sign in with Google</div>
      <img className="w-6 h-6" src={googleIcon}></img>
    </button>
  );
}

async function signInWithEmailLink(email: string) {
  const actionCodeSettings: ActionCodeSettings = {
    url: `${CLIENT_URL}${CLIENT_ROUTE.VERIFY_EMAIL}?email=${encodeURIComponent(email)}`,
    handleCodeInApp: true,
  };

  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {})
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert("Error sending email verification. Please try again.");
    });
}

// API call extracted into a separate function
export async function createUserInAPI(
  firebaseUser: FirebaseUser,
  dispatch: Dispatch
): Promise<GetUserResponse | null> {
  dispatch(setFirebaseUser({ firebaseUser: firebaseUser }));
  const user: User = createUserFromFirebaseUser(firebaseUser);
  try {
    const res = await createUserAPI(user, dispatch);
    return res;
  } catch (error) {
    console.error("Error creating user", error);
    return null;
  }
}

export default SignIn;
