import { ArrowRight, Check, Coffee, DraftingCompass, Sparkle, Sparkles } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { APPLICATION_NAME, CLIENT_ROUTE } from "src/utils/constants";
import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect, useState } from "react";
import { LandingPageFooter, Toolbar } from "./LandingPage";
import { joinWaitlist } from "src/api/service/userService";
const demoVideo = require("../../assets/videos/demo_v1.mov");
const overview_img_1 = require("../../assets/images/overview_img_1.png");
const overview_img_2 = require("../../assets/images/overview_img_2.png");
const overview_img_3 = require("../../assets/images/overview_img_3.png");

function JoinWaitlist() {
  return (
    <div className="flex flex-col w-full h-screen bg-neutral-50">
      <Toolbar />
      <Body />
      {/* <HowItWorks /> */}
      <LandingPageFooter />
    </div>
  );
}

function Body() {
  return (
    <div className="flex flex-col items-between h-full w-full">
      <div
        className="flex flex-col items-center w-full py-8 bg-neutral-50
      md:py-16 md:px-16
      lg:py-24 lg:px-24
      "
      >
        <div className="flex flex-col items-center w-full">
          <div
            className="text-center text-5xl font-bold text-neutral-900
          w-full 
          lg:w-1/2 
          "
          >
            <span>We're </span>
            <span className="text-indigo-500">improving </span>
            <span>workflows, one team at a time.</span>
          </div>
          <div className="flex justify-center text-center mt-8 text-xl text-neutral-700 w-3/5">
            Since you're here, you're probably interested in what we're building. We're currently in
            beta and would love to have you join us. 👇👇
          </div>
        </div>

        <div className="flex flex-col items-center w-full">
          <JoinWaitlistForm />
        </div>
      </div>
    </div>
  );
}

function HowItWorks() {
  return (
    <div className="flex flex-col items-center w-full py-8 bg-neutral-100">
      <div className="text-4xl font-bold text-neutral-900">How it works</div>
      <div
        className="flex flex-col items-center w-full mt-8
      md:flex-row md:space-x-8
      lg:flex-row lg:space-x-8
      "
      >
        <div className="flex flex-col items-center w-full">
          <div className="flex items-center justify-center w-16 h-16 bg-neutral-200 rounded-full">
            <Sparkle size={24} />
          </div>
          <div className="text-lg font-bold text-neutral-900">Create a document</div>
          <div className="text-neutral-700">Use our editor to create a document with ease</div>
        </div>

        <div className="flex flex-col items-center w-full">
          <div className="flex items-center justify-center w-16 h-16 bg-neutral-200 rounded-full">
            <DraftingCompass size={24} />
          </div>
          <div className="text-lg font-bold text-neutral-900">Collaborate</div>
          <div className="text-neutral-700">
            Share your document with others and collaborate in real-time
          </div>
        </div>

        <div className="flex flex-col items-center w-full">
          <div className="flex items-center justify-center w-16 h-16 bg-neutral-200 rounded-full">
            <Check size={24} />
          </div>
          <div className="text-lg font-bold text-neutral-900">Export</div>
          <div className="text-neutral-700">Export your document to PDF, Word, and more</div>
        </div>
      </div>
    </div>
  );
}

function JoinWaitlistForm() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [sizeOfCompany, setSizeOfCompany] = useState("");
  const [email, setEmail] = useState("");

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const validEmail = email.includes("@") && email.includes(".");
  const validInput = name.length > 0 && validEmail;

  async function handleJoinWaitlist() {
    await joinWaitlist(email, name, company, sizeOfCompany)
      .then((response) => {
        console.log(response);
        displaySuccess();
      })
      .catch((error) => {
        console.log(error);
        displayFailure();
      });
  }

  function displaySuccess() {
    // setName("");
    // setCompany("");
    // setSizeOfCompany("");
    // setEmail("");

    setFailure(false);
    setSuccess(true);
  }

  function displayFailure() {
    setSuccess(false);
    setFailure(true);
  }

  return (
    <div className="flex flex-col items-center w-full mt-8">
      {success && (
        <div className="text-lg text-green-600">Success! You have been added to the waitlist!</div>
      )}
      {failure && (
        <div className="text-lg text-red-600">
          Failed to join the waitlist. Please try again later.
        </div>
      )}
      <div
        className="flex flex-col justify-center items-center  mt-4 space-y-2
      w-2/3 
      lg:w-3/5
      xl:max-w-xl
      "
      >
        <div
          className="flex flex-col lg:flex-row justify-center items-center w-full  mt-4 
          space-y-2
          lg:space-x-2 lg:space-y-0

        "
        >
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Name (required)"
            className="w-full px-4 py-2 text-lg text-neutral-900 border-2 border-neutral-300 rounded-md focus:outline-none focus:border-primary-500"
          />
          <input
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            type="text"
            placeholder="Company (optional)"
            className="w-full px-4 py-2 text-lg text-neutral-900 border-2 border-neutral-300 rounded-md focus:outline-none focus:border-primary-500"
          />
        </div>
        <input
          value={sizeOfCompany}
          onChange={(e) => setSizeOfCompany(e.target.value)}
          placeholder="Size of company Ex. 100 (optional)"
          className="w-full px-4 py-2 text-lg text-neutral-900 border-2 border-neutral-300 rounded-md focus:outline-none focus:border-primary-500"
        />
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Email (required)"
          className="w-full px-4 py-2 text-lg text-neutral-900 border-2 border-neutral-300 rounded-md focus:outline-none focus:border-primary-500"
        />
        {/* </div> */}
      </div>

      <div className="flex flex-col items-center w-full mt-8">
        <button
          onClick={async () => {
            await handleJoinWaitlist();
          }}
          disabled={!validInput}
          className={`w-64 px-4 py-4 text-lg rounded-md
          ${
            validInput
              ? "text-white bg-indigo-500 hover:bg-indigo-700"
              : "text-white bg-neutral-500"
          }
          `}
        >
          Join the waitlist
        </button>
      </div>
    </div>
  );
}

export default JoinWaitlist;
