import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";

import { FloatingCopyButton } from "./ICDDescriptionCell";
import { setInterfaceIdPrefix, setNodeLabel } from "src/state/slices/documentSlice";
import { NodeData } from "src/models/BoxDiagram/Node";
import { Node as RFNode } from "reactflow";
import { getNodeById } from "src/state/selectors/boxDiagramSelectors";
import { setZoomToNode } from "src/state/slices/BoxDiagram/boxDiagramUISlice";
import { setSelectedSection } from "src/state/slices/sidebarSlice";
import { SideNavigationBarSection } from "src/state/slices/uiSlice";
import { applyNewNodeChanges } from "src/state/reducers/boxDiagramReducers";
import { navigateToBoxDiagram } from "src/state/selectors/selectors";

interface ICDComponentCellProps {
  nodeID: string;
  columnSize: number;
  rowIndex: number;
  colID: string;
  onChange?: (newValue: string) => void;
}

function ICDComponentCell({
  rowIndex,
  columnSize,
  nodeID,
  colID,
  onChange,
}: ICDComponentCellProps) {
  const [showButton, setShowButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  const node = useSelector((state: RootState) => {
    return getNodeById(state.document.documentContainer.nodes, nodeID);
  });

  const label = node != undefined ? node.data.label : "";

  const interfaceIDPrefix = useSelector(
    (state: RootState) => state.document.documentContainer.id_prefix
  );
  const interfaceID = `${interfaceIDPrefix}_${rowIndex + 1}`;

  // Focus the input when editing starts
  useEffect(() => {
    // Automatically exit edit mode when clicking outside the cell
    function handleClickOutside(event: MouseEvent) {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        // if (onChange) onChange(editValue);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, onChange]);

  function handleOnClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!isEditing) {
      setIsEditing(true);
    }
  }

  function handleStopEditing() {
    setIsEditing(false);
    setShowButton(false);
  }

  return (
    <div
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      ref={cellRef}
      className="relative flex items-start justify-start px-3 pt-3 h-full text-lg"
      onClick={handleOnClick}
      style={{ width: columnSize, wordBreak: 'break-word' }}
      >
      {isEditing && <PrefixPicker node={node} handleStopEditing={handleStopEditing} />}
      {/* {showButton && !isEditing && <FloatingCopyButton text={formatTextForCopy(legendItems)} />} */}
      <div className="text-md">{label}</div>

      {showButton && !isEditing && <FloatingCopyButton text={interfaceID} />}
    </div>
  );
}

/**
 * PrefixPicker - A component that allows users to select a direction for a connection.
 */
interface PrefixPickerProps {
  node: RFNode<NodeData>;
  handleStopEditing: () => void;
}
function PrefixPicker({ node, handleStopEditing }: PrefixPickerProps) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(node.data.label);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleOnBlur() {
    dispatch(setNodeLabel({ nodeId: node.id, label: value }));
  }

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      handleOnBlur();
      handleStopEditing();
    }
  }

  return (
    <div className="absolute top-0 left-0 flex flex-col w-60 rounded-lg shadow-lg bg-white border z-50">
      <PrefixPickerTitle />
      <input
        className="m-3 border border-gray-300 rounded-md p-1"
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
      />
      <ViewInDiagramButton nodeID={node.id} />
    </div>
  );
}

function ViewInDiagramButton({ nodeID }: { nodeID: string }) {
  const dispatch = useDispatch();

  function handleClick() {
    navigateToBoxDiagram(dispatch, nodeID);
  }
  return (
    <button
      onClick={handleClick}
      className="my-1 mx-3  text-sm font-medium text-neutral-500 hover:text-blue-800   py-2 "
    >
      View in diagram
    </button>
  );
}

function PrefixPickerTitle() {
  return (
    <span className="text-neutral-500 pl-3 py-2 text-sm font-medium">Update component label</span>
  );
}

export default ICDComponentCell;
