import { useState } from "react";
import { useDispatch } from "react-redux";
import { BorderStyle, NodeData } from "src/models/BoxDiagram/Node";
import { updateNodeData } from "src/state/reducers/boxDiagramReducers";
import { ToolbarButton, VerticalDivider } from "../BaseToolbar";
import { Color } from "src/models/BoxDiagram/Colors";
import { BoxSelect, MinusCircle, PlusCircle, Scan, Square } from "lucide-react";
import { NodeBarMenuItem } from "./BaseNodeToolbar";

const COLORS: string[] = [
  Color.Red.css.fifty,
  Color.Red.css.twoHundred,
  Color.Red.css.fiveHundred,
  Color.White.css.fifty,
  Color.Orange.css.fifty,
  Color.Orange.css.twoHundred,
  Color.Orange.css.fiveHundred,
  Color.Gray.css.fifty,
  Color.Yellow.css.fifty,
  Color.Yellow.css.twoHundred,
  Color.Yellow.css.fiveHundred,
  Color.Gray.css.oneHundred,
  Color.Lime.css.fifty,
  Color.Lime.css.twoHundred,
  Color.Lime.css.fiveHundred,
  Color.Gray.css.twoHundred,
  Color.Green.css.fifty,
  Color.Green.css.twoHundred,
  Color.Green.css.fiveHundred,
  Color.Gray.css.fourHundred,
  Color.Blue.css.fifty,
  Color.Blue.css.twoHundred,
  Color.Blue.css.fiveHundred,
  Color.Gray.css.fiveHundred,
  Color.Indigo.css.fifty,
  Color.Indigo.css.twoHundred,
  Color.Indigo.css.fiveHundred,
  Color.Gray.css.sixHundred,
  Color.Purple.css.fifty,
  Color.Purple.css.twoHundred,
  Color.Purple.css.fiveHundred,
  Color.Black.css.fiveHundred,
];

interface BorderPickerSectionProps {
  nodeId: string;
  nodeData: NodeData;
  selectedSubMenu: NodeBarMenuItem | null;
  setSelectedSubMenu: (selectedSubMenu: NodeBarMenuItem | null) => void;
}

function BorderPickerSection({
  nodeId,
  nodeData,
  selectedSubMenu,
  setSelectedSubMenu,
}: BorderPickerSectionProps) {
  const dispatch = useDispatch();

  // State for selected color, color picker visibility, and original color
  const [selectedColor, setSelectedColor] = useState<string>(nodeData.temp_border_color);

  // Sets the selected color and hides the color picker. This is called when use user selects a new color
  function setColor(color: string) {
    // Set local color
    setSelectedColor(color);
    // Hide the color picker
    setSelectedSubMenu(null);
    // Dispatch the color change globally
    dispatch(
      updateNodeData({
        id: nodeId,
        newData: { border_color: color, temp_border_color: color },
      })
    );
  }

  // Previews a color without setting it
  function previewColor(color: string) {
    setSelectedColor(color);
    dispatch(updateNodeData({ id: nodeId, newData: { border_color: color } }));
  }

  // Resets to the original color. This happens hovers and does NOT select a new color
  function resetOriginalColor() {
    // Set local color
    setSelectedColor(nodeData.temp_border_color);
    // Dispatch the color change globally
    dispatch(updateNodeData({ id: nodeId, newData: { border_color: nodeData.temp_border_color } }));
  }

  // Toggle the visibility of the color picker
  function toggleBorderPicker() {
    setSelectedSubMenu(
      selectedSubMenu === NodeBarMenuItem.BorderColor ? null : NodeBarMenuItem.BorderColor
    );
  }

  return (
    <div>
      <ToolbarButton onClick={toggleBorderPicker} tooltip="Border color">
        <ColoredCircle bg={selectedColor} />
        {/* Positioning the BorderPicker below the button */}
        {selectedSubMenu === NodeBarMenuItem.BorderColor && (
          <div className="absolute top-full mt-2 ">
            <BorderStyleSection nodeId={nodeId} nodeData={nodeData} />
            <BorderSizePicker
              nodeId={nodeId}
              currentSize={typeof nodeData.border_width === "number" ? nodeData.border_width : 2}
            />
            <BorderPicker
              onSelectColor={setColor}
              onPreviewColor={previewColor}
              resetOriginalColor={resetOriginalColor}
            />
          </div>
        )}
      </ToolbarButton>
    </div>
  );
}

interface BorderPickerProps {
  onSelectColor: (color: string) => void;
  onPreviewColor: (color: string) => void;
  resetOriginalColor: () => void;
}

// Component to pick a color from the available options
function BorderPicker({ onSelectColor, onPreviewColor, resetOriginalColor }: BorderPickerProps) {
  return (
    <div className="grid grid-cols-4 gap-3 bg-white rounded-lg shadow-xl w-44 p-2 py-4">
      {COLORS.map((colorStr, index) => (
        <div
          onMouseLeave={resetOriginalColor}
          onMouseEnter={() => onPreviewColor(colorStr)}
          onClick={() => onSelectColor(colorStr)}
          key={colorStr}
        >
          <ColoredCircle bg={colorStr} />
        </div>
      ))}
    </div>
  );
}

function ColoredCircle({ bg }: { bg: string }) {
  return (
    <div className="flex justify-center w-10">
      <div
        className={
          "flex items-center justify-center w-7 h-7 rounded-full border border-gray-400 cursor-pointer "
        }
        style={{ backgroundColor: bg }}
      >
        <div className="w-4 h-4 rounded-full bg-white border border-gray-400 cursor-pointer " />
      </div>
    </div>
  );
}

interface BorderSizePickerProps {
  nodeId: string;
  currentSize: number;
}

// Component to pick a color from the available options
function BorderSizePicker({ nodeId, currentSize }: BorderSizePickerProps) {
  const dispatch = useDispatch();
  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }
  function increaseBorderSize() {
    if (currentSize >= 10) return;
    dispatch(updateNodeData({ id: nodeId, newData: { border_width: currentSize + 1 } }));
  }
  function decreaseBorderSize() {
    console.log("Decrease border size");
    if (currentSize <= 0) return;
    dispatch(updateNodeData({ id: nodeId, newData: { border_width: currentSize - 1 } }));
  }

  return (
    <div
      onClick={stopPropagation}
      className="flex justify-between items-center bg-white border-t border-b border-gray-500 "
    >
      <div onClick={decreaseBorderSize} className="flex items-center justify-center p-3 ">
        <MinusCircle />
      </div>
      <VerticalDivider />
      <div className="flex items-center justify-center px-2">{currentSize} px</div>
      <VerticalDivider />
      <div onClick={increaseBorderSize} className="flex items-center justify-center p-3 ">
        <PlusCircle />
      </div>
    </div>
  );
}

interface BorderStyleSectionProps {
  nodeId: string;
  nodeData: NodeData;
}
function BorderStyleSection({ nodeId, nodeData }: BorderStyleSectionProps) {
  const dispatch = useDispatch();

  const [showBorderPicker, setShowBorderPicker] = useState(false);
  // State for selected color, color picker visibility, and original color
  const [selectedStyle, setSelectedStyle] = useState<BorderStyle>(
    nodeData.temp_border_style || BorderStyle.Solid
  );

  // Sets the selected color and hides the color picker. This is called when use user selects a new color
  function setColor(style: BorderStyle) {
    // Set local color
    setSelectedStyle(style);
    // Hide the color picker
    setShowBorderPicker(false);
    // Dispatch the color change globally
    dispatch(
      updateNodeData({
        id: nodeId,
        newData: { border_style: style, temp_border_style: style },
      })
    );
  }

  // Previews a color without setting it
  function previewStyle(style: BorderStyle) {
    setSelectedStyle(style);
    dispatch(updateNodeData({ id: nodeId, newData: { border_style: style } }));
  }

  // Resets to the original color. This happens hovers and does NOT select a new color
  function resetOriginalStyle() {
    // Set local color
    setSelectedStyle(nodeData.temp_border_style || BorderStyle.Solid);
    // Dispatch the color change globally
    dispatch(updateNodeData({ id: nodeId, newData: { border_style: nodeData.temp_border_style } }));
  }

  return (
    <div>
      <BorderStylePicker
        selectedStyle={selectedStyle}
        onSelectStyle={setColor}
        onPreviewStyle={previewStyle}
        resetOriginalStyle={resetOriginalStyle}
      />
    </div>
  );
}

interface BorderStylePicker {
  selectedStyle: BorderStyle;
  onSelectStyle: (color: BorderStyle) => void;
  onPreviewStyle: (color: BorderStyle) => void;
  resetOriginalStyle: () => void;
}

// Component to pick a color from the available options
function BorderStylePicker({
  selectedStyle,
  onSelectStyle,
  onPreviewStyle,
  resetOriginalStyle,
}: BorderStylePicker) {
  return (
    <div className="flex justify-between w-full bg-white pt-2 px-2   border-gray-500">
      {Object.keys(BorderStyle).map((style, index) => {
        return (
          <div
            onMouseLeave={resetOriginalStyle}
            onMouseEnter={() => onPreviewStyle(style as BorderStyle)}
            onClick={() => onSelectStyle(style as BorderStyle)}
            key={style}
          >
            <BorderStyleDisplay style={style as BorderStyle} selectedStyle={selectedStyle} />
          </div>
        );
      })}
    </div>
  );
}

function BorderStyleDisplay({
  style,
  selectedStyle,
}: {
  style: BorderStyle;
  selectedStyle: BorderStyle;
}) {
  const styleIconMap: Record<BorderStyle, JSX.Element> = {
    solid: <Square strokeWidth={1.5} size={30} />,
    dashed: <Scan strokeWidth={1.5} size={30} />,
    dotted: <BoxSelect strokeWidth={1.5} size={30} />,
  };

  const selected = style.toLocaleLowerCase() === selectedStyle.toLocaleLowerCase();
  const selectedBg = selected ? "bg-indigo-50" : "";

  return (
    <div className={"flex justify-center items-center p-2 rounded-lg " + selectedBg}>
      {styleIconMap[style.toLocaleLowerCase()]}
    </div>
  );
}

export default BorderPickerSection;
