import { ArrowRight, Check, Coffee, Headphones, LogIn, Sparkles, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APPLICATION_NAME, CLIENT_ROUTE, CLIENT_URL } from "src/utils/constants";
import { handleTryMeOutClick } from "./LandingPage";
import { createCheckoutChooseWhatToPay } from "src/api/service/stripeService";
import { useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { getCalApi } from "@calcom/embed-react";
import { isSignedIn } from "src/utils/helpers";
import { StripeProductType, Subscription } from "src/models/Subscription";
import { Slider } from "@mui/material";
import { Color } from "src/models/BoxDiagram/Colors";

const packages = [
  {
    name: "Pay what you think is fair",
    subName: "(Individual plan)",
    monthlyPrice: 25,
    yearlyPrice: 250,
    numBlocks: "Unlimited",
    numSeats: "1 seat",
    sso: null,
    versionControl: null,
    support: "Priority email support",
    documentsPerUser: "Unlimited docs /user",
    perUserText: "/month",
    perUserTextAnnual: "annually",
    productType: StripeProductType.INDIVIDUAL,
    description:
      "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const pricingOptions = [1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000];
const defaultOptions = [pricingOptions[1], pricingOptions[3], pricingOptions[5]];

function ChooseWhatToPay() {
  const [isYearly, setIsYearly] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const skipPassword = searchParams.get("skip");

  useEffect(() => {
    if (skipPassword === "openSesame") {
      setShowPassword(false);
    }
  }, []);

  function handlePassword() {
    if (password.toLowerCase() === "dispatch") {
      setShowPassword(false);
    } else {
      setPassword("");
      setShowError(true);
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handlePassword();
    }
  }
  if (showPassword) {
    return (
      <div className="flex flex-col items-center justify-center w-screen h-screen space-y-4">
        <h1 className="text-2xl font-semibold mb-8">Enter password to continue</h1>
        <input
          className="border-2 border-gray-300 p-2 rounded-lg"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter password"
        />
        <button
          onClick={handlePassword}
          className="flex justify-center items-center space-x-2 w-32 h-12 rounded-lg text-indigo-700 bg-white border border-indigo-700 hover:bg-indigo-50 "
        >
          <span>Enter</span>
          <LogIn />
        </button>
        {showError && (
          <div className="text-red-500 text-sm">Password is incorrect. Please try again.</div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Toolbar />
      <div className="py-10 md:px-14 p-4 max-w-screen-2xl mx-auto text-neutral-800">
        <div className="text-center">
          <Header />
          {/* <PriceToggle isYearly={isYearly} setIsYearly={setIsYearly} /> */}
          <PricingCards isYearly={isYearly} />
        </div>
      </div>
    </div>
  );
}

function Header() {
  return (
    <div>
      <span className="md:text-6xl text-3xl font-semibold text-gray-400 mb-2 line-through">
        Our pricing
      </span>
      <span className="md:text-6xl text-3xl font-semibold text-neutral-800 mb-2">
        {" Your productivity hack"}
      </span>
      {/* <div className="mt-8 text-xl font-medium md:w-2/3 mx-auto"> */}
      <div className="mt-8 p-6 text-lg md:text-xl  md:w-2/3 mx-auto rounded-lg bg-indigo-50 border border-indigo-700 text-indigo-900">
        {/* No more manually entering the same information into different docs, just a little investment
        to 10X your productivity. */}
        {/* Our engineers dedicated long hours to bring you a tool you can trust. Pay what feels right.
        Hacker Pulse readers get exclusive access to this plan.
         */}
        <div className="mb-4">
          Fueled by passion and caffeine, our engineers have dedicated long hours to bring you a
          tool you can trust.
        </div>
        As a Hacker Pulse reader, you have the unique opportunity to pay what feels right while we
        are in beta.
      </div>
    </div>
  );
}

function PricingCards({ isYearly }: { isYearly: boolean }) {
  const [selectedPrice, setSelectedPrice] = useState<number>(defaultOptions[1]);
  const [showCustomPrice, setShowCustomPrice] = useState(false);
  const [signedIn, setSignedIn] = useState(false);

  const user = useSelector((state: RootState) => state.user.user);
  const subscription = useSelector((state: RootState) => state.user.subscription);
  const firebaseUser = useSelector((state: RootState) => state.user.firebaseUser);
  const optionalEmail: string | null = user ? user.email : null;

  const cancel_url = `${CLIENT_URL}${CLIENT_ROUTE.HACKER_PULSE}?skip=openSesame`;

  useEffect(() => {
    setSignedIn(isSignedIn(firebaseUser, user));
  }, []);

  function handleCheckoutClick() {
    createCheckoutChooseWhatToPay(optionalEmail, selectedPrice, cancel_url)
      .then((checkoutUrl) => {
        console.log("Redirecting to checkout page", checkoutUrl);
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        console.error("Failed to redirect to checkout page", error);
        alert("Failed to redirect to checkout. Please try again.");
      });
  }

  function toggleShowCustomPrice() {
    setShowCustomPrice(!showCustomPrice);
  }

  return (
    <div className="max-w-sm md:max-w-xl lg:max-w-2xl mt-20 mx-auto">
      <HackerPulseTag />

      {packages.map((pkg, index) => (
        <div
          key={index}
          className="py-10 md:px-6 px-4 rounded-lg shadow-3xl text-neutral-800 border-2 border-neutral-500 "
        >
          <h3 className="text-3xl font-bold text-center ">{pkg.name}</h3>
          <h3 className="text-xl  text-center ">{pkg.subName}</h3>

          <PriceOptions selectedPrice={selectedPrice} setSelectedPrice={setSelectedPrice} />
          <button onClick={toggleShowCustomPrice} className="font-medium underline mt-4">
            Choose custom amount
          </button>
          {showCustomPrice && (
            <PaymentSlider selectedPrice={selectedPrice} setSelectedPrice={setSelectedPrice} />
          )}

          <ul className="mt-4 space-y-3 font-medium">
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              Block diagram editor
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              ICD editor
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              DSM editor
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              {pkg.numBlocks} blocks /document
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              {pkg.documentsPerUser}
            </li>
            <li className="flex items-center">
              <Check className="mr-2 text-xl" color="green" />
              {pkg.numSeats}
            </li>
            <li className="flex items-center">
              {pkg.versionControl ? (
                <Check className="mr-2 text-xl" color="green" />
              ) : (
                <X className="mr-2 text-xl" color="gray" />
              )}
              Version control
            </li>
            <li className="flex items-start justify-start ">
              <div>
                {pkg.name !== "Hobby" ? (
                  <Headphones className="mr-2 text-xl" color="green" />
                ) : (
                  <Headphones className="mr-2 text-xl" color="gray" />
                )}
              </div>
              <div className="flex text-start items-start justify-start">{pkg.support}</div>
            </li>
          </ul>

          <ContinueButton handleCheckoutClick={handleCheckoutClick} signedIn={signedIn} />

          {/* {getActionBtn(pkg.productType, signedIn, subscription, handleCheckoutClick, navigateTo)} */}
        </div>
      ))}
    </div>
  );
}

function PriceOptions({
  selectedPrice,
  setSelectedPrice,
}: {
  selectedPrice: number;
  setSelectedPrice: (price: number) => void;
}) {
  return (
    <div className="flex flex-wrap items-center justify-center space-x-6 w-full mb-8">
      {defaultOptions.map((price, index) => (
        <PriceOption
          price={price}
          key={index}
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
        />
      ))}
    </div>
  );
}

function PriceOption({
  price,
  selectedPrice,
  setSelectedPrice,
}: {
  price: number;
  selectedPrice: number;
  setSelectedPrice: (price: number) => void;
}) {
  const selected = selectedPrice == price;
  const selectedStyle = "text-indigo-700 shadow-2xl border border-indigo-400 ";
  const unselectedStyle = "text-neutral-500 bg-neutral-100 border border-transparent";

  function handleClick() {
    setSelectedPrice(price);
  }

  return (
    <button
      onClick={handleClick}
      className={`flex flex-col items-center  justify-center w-28 mt-4 md:mt-12 p-4 rounded-lg ${
        selected ? selectedStyle : unselectedStyle
      }`}
    >
      <span className="font-bold text-2xl">{`$${price / 100}`}</span>
      <span className="font-medium text-lg">/month</span>
    </button>
  );
}

function PaymentSlider({
  selectedPrice,
  setSelectedPrice,
}: {
  selectedPrice: number;
  setSelectedPrice: (price: number) => void;
}) {
  function handleChange(event: any, value: number | number[]) {
    setSelectedPrice(value as number);
  }
  return (
    <div className="flex flex-col items-center justify-center mx-auto mt-5 w-4/5 p-6 border rounded-xl">
      <div className="mb-4 text-lg font-bold">Choose your price</div>
      <div className="text-3xl mb-8 font-extrabold text-indigo-700">{`$${
        selectedPrice / 100
      }/mo`}</div>

      <Slider
        style={{ color: Color.Neutral.css.sevenHundred }}
        aria-label="Small steps"
        value={selectedPrice}
        onChange={handleChange}
        step={500}
        marks
        min={pricingOptions[0]}
        max={pricingOptions[pricingOptions.length - 1]}
        valueLabelDisplay="auto"
      />
    </div>
  );
}

function ContinueButton({
  handleCheckoutClick,
  signedIn,
}: {
  handleCheckoutClick: () => void;
  signedIn: boolean;
}) {
  const navigate = useNavigate();
  const subscription = useSelector((state: RootState) => state.user.subscription);
  function navigateTo(route: string) {
    navigate(route);
  }

  function handleOnClick() {
    // If user clicks on the subscription they already have, navigate to the document home
    if (subscription && subscription.product_type === StripeProductType.INDIVIDUAL) {
      navigateTo(CLIENT_ROUTE.DOCUMENT_HOME);
    } else if (signedIn) {
      handleCheckoutClick();
    } else {
      navigateTo(CLIENT_ROUTE.SIGN_IN);
    }
  }
  return (
    <div className="w-full flex items-center justify-center mt-5">
      <button
        onClick={handleOnClick}
        className="w-1/2 mt-6 px-8 py-3 text-xl bg-neutral-700 text-white border border-neutral-300 hover:bg-neutral-800 font-semibold rounded-lg"
      >
        Continue
      </button>
    </div>
  );
}

function HackerPulseTag() {
  return (
    <div className="flex items-center justify-center space-x-2 p-2 mb-2 text-center text-white md:px-2 w-56 rounded-full bg-indigo-700">
      <Sparkles size={18} />
      <span>Hacker Pulse exclusive</span>
    </div>
  );
}

interface PriceToggleProps {
  isYearly: boolean;
  setIsYearly: (isYearly: boolean) => void;
}
function PriceToggle({ isYearly, setIsYearly }: PriceToggleProps) {
  function setMonthly() {
    setIsYearly(false);
  }

  function setYearly() {
    setIsYearly(true);
  }

  const selectedStyle = "p-2 px-6 rounded-full text-neutral-100 bg-neutral-700";
  const unselectedStyle = "p-2 px-6 rounded-full text-neutral-500";
  return (
    <div className="flex items-center justify-center w-full mt-12">
      <div className="flex items-center justify-between rounded-full border-2 border-neutral-400">
        <div className="flex space-x-1 p-1">
          <button onClick={setMonthly} className={isYearly ? unselectedStyle : selectedStyle}>
            Monthly
          </button>
          <button onClick={setYearly} className={isYearly ? selectedStyle : unselectedStyle}>
            Annually
          </button>
        </div>
      </div>
    </div>
  );
}

function Toolbar() {
  const navigate = useNavigate();

  // Function to handle navigation on button click
  function handleTryDemo() {
    handleTryMeOutClick(navigate);
  }

  function handleNavigateHome() {
    navigate(CLIENT_ROUTE.HOME);
  }

  function navToPricing() {
    navigate(CLIENT_ROUTE.PRICING);
  }
  return (
    <div className="flex items-center justify-between w-full h-16 bg-neutral-800">
      <button
        onClick={handleNavigateHome}
        className="flex items-center justify-start pl-8 text-white text-2xl font-mono"
      >
        <Coffee size={24} className="mr-2" />
        {APPLICATION_NAME}
      </button>

      <div className="flex items-center pr-4 text-white rounded-md">
        <button
          onClick={handleTryDemo}
          className="flex items-center pl-4 py-2 mx-2 space-x-2 text-white rounded-md"
        >
          <div>Try the demo</div>
          <ArrowRight size={18} />
        </button>
      </div>
    </div>
  );
}

export default ChooseWhatToPay;
