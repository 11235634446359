// services/threadService.js

import axios from "axios";
import { setUser } from "../../state/slices/userSlice";
import { Dispatch } from "redux";
import { User } from "src/models/User";
import { GeneralResponse, GetUserResponse } from "src/utils/Api";
import { LOCAL_STORAGE, SERVER_ROUTE } from "src/utils/constants";
import { getAndSetSubscription } from "./stripeService";

const HEADERS = {
  "Content-Type": "application/json",
};

export async function getUser(id: string, dispatch: Dispatch): Promise<GetUserResponse> {
  console.log(`123 ${SERVER_ROUTE.USER.GET}`);
  try {
    const response = await axios.post(
      SERVER_ROUTE.USER.GET,
      { id: id },
      {
        headers: HEADERS,
      }
    );
    const data = response.data as GetUserResponse;
    // Dispatch the action to set the threads in the Redux store
    dispatch(setUser({ user: data.resp }));
    // Get the subscription for the user
    await getAndSetSubscription(id, dispatch);

    return data;
  } catch (error) {
    throw error;
  }
}

export async function createUserAPI(user: User, dispatch: Dispatch) {
  console.log(`1234 ${SERVER_ROUTE.USER.CREATE}`);
  try {
    const response = await axios.post(
      SERVER_ROUTE.USER.CREATE,
      { ...user },
      {
        headers: HEADERS,
      }
    );
    const data = response.data as GetUserResponse;
    // Dispatch the action to set the threads in the Redux store
    dispatch(setUser({ user: data.resp }));

    // Save the userID to localStorage
    localStorage.setItem(LOCAL_STORAGE.USER_ID, user.id);

    return data;
  } catch (error) {
    throw error;
  }
}

export async function joinWaitlist(
  email: string,
  name: string,
  company: string,
  sizeOfCompany: string
) {
  try {
    const response = await axios.post(
      SERVER_ROUTE.WAITLIST.JOIN,
      { email, name, company, sizeOfCompany },
      { headers: HEADERS }
    );
    const data = response.data as GeneralResponse;
    return data;
  } catch (error) {
    throw error;
  }
}
