import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/slices";
import { SubConnection } from "src/models/Connection";
import { ArrowDirection, EdgeData } from "src/models/BoxDiagram/Edge";
import { setArrowDirection } from "src/state/slices/documentSlice";
import { getEdgeBySourceAndTarget } from "src/state/selectors/boxDiagramSelectors";
import { Edge } from "reactflow";
import { ArrowLeftRight, Minus, MoveLeft, MoveRight, RouteOff } from "lucide-react";
import { FloatingCopyButton } from "./ICDDescriptionCell";

interface ICDDirectionCellProps {
  rowIndex: number;
  columnSize: number;
  colID: string;
  edge: Edge<EdgeData>;
  onChange?: (newValue: string) => void;
}

function ICDDirectionCell({ rowIndex, colID, columnSize, edge, onChange }: ICDDirectionCellProps) {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const cellRef = useRef<HTMLDivElement>(null);
  const directionLabel = getArrowDirectionString(edge?.data.arrow_direction);

  // Focus the input when editing starts
  useEffect(() => {
    // Automatically exit edit mode when clicking outside the cell
    function handleClickOutside(event: MouseEvent) {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        // if (onChange) onChange(editValue);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, onChange]);

  function formatTextForCopy(legendItems: SubConnection[]) {
    const itemsWithoutTrailingComma = legendItems.map((item) => item.label).join(", ");
    return itemsWithoutTrailingComma;
  }

  function handleOnClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!isEditing) {
      // setIsEditing(true);
      cycleArrowDirection();
    }
  }

  // Function to cycle through the arrow locations
  function cycleArrowDirection() {
    const currentDirection = edge?.data.arrow_direction;
    let nextSelection;
    if (currentDirection === ArrowDirection.NoDirection) {
      nextSelection = ArrowDirection.ToTarget;
    } else if (currentDirection === ArrowDirection.ToTarget) {
      nextSelection = ArrowDirection.ToSource;
    } else if (currentDirection === ArrowDirection.ToSource) {
      nextSelection = ArrowDirection.Both;
    } else if (currentDirection === ArrowDirection.Both) {
      nextSelection = ArrowDirection.NoDirection;
    } else {
      throw new Error("Invalid arrow direction");
    }

    // Dispatch the update inside the setState callback to ensure
    // the update is applied before the next render
    if (edge) {
      // Save state since cross document undo/redo is not supported yet
      dispatch(setArrowDirection({ edge: edge, arrowDirection: nextSelection }));
    }

    return nextSelection;
  }

  return (
    <div
      // onMouseEnter={() => setShowButton(true)}
      // onMouseLeave={() => setShowButton(false)}
      ref={cellRef}
      className="relative flex items-start justify-start pl-3 pt-3 h-full text-lg"
      onClick={handleOnClick}
      style={{ width: columnSize }}
    >
      {isEditing && <PopupWrapper edge={edge} setIsEditing={setIsEditing} />}
      {/* {showButton && <FloatingCopyButton text={directionLabel} />} */}
      <div className="flex text-md justify-start items-center">
        <ArrowIcon arrowDirection={edge?.data.arrow_direction} />
        <div className="pl-2">{directionLabel}</div>
      </div>
    </div>
  );
}

interface PopupWrapperProps {
  edge: Edge<EdgeData> | undefined;
  setIsEditing: (isEditing: boolean) => void;
}
function PopupWrapper({ edge, setIsEditing }: PopupWrapperProps) {
  return (
    <>
      {edge?.data.arrow_direction !== undefined ? (
        <DirectionPicker edge={edge} setIsEditing={setIsEditing} />
      ) : (
        <NotConnectedDialog setIsEditing={setIsEditing} />
      )}
    </>
  );
}

/**
 * NotConnectedDialog - A component that displays when the user tries to edit a connection
 * that is not connected to a node.
 */
interface NotConnectedDialogProps {
  setIsEditing: (isEditing: boolean) => void;
}
function NotConnectedDialog({ setIsEditing }: NotConnectedDialogProps) {
  return (
    <div
      className="absolute top-0 left-0 flex flex-col w-60 rounded-lg shadow-lg bg-white border pb-4 z-50"
      onClick={() => setIsEditing(false)}
    >
      <span className="text-neutral-500 p-4 text-sm font-medium">
        This component is not connect to anything. Please add connection to edit direction.
      </span>
    </div>
  );
}

/**
 * DirectionPicker - A component that allows users to select a direction for a connection.
 */
interface DirectionPickerProps {
  edge: Edge<EdgeData> | undefined;
  setIsEditing: (isEditing: boolean) => void;
}
function DirectionPicker({ edge, setIsEditing }: DirectionPickerProps) {
  const dispatch = useDispatch();
  const [selectedDirection, setSelectedDirection] = useState(edge.data.arrow_direction);

  function updateDirection(direction: ArrowDirection) {
    setSelectedDirection(direction);
    setIsEditing(false);
    dispatch(setArrowDirection({ edge: edge, arrowDirection: direction }));
  }

  return (
    <div className="absolute top-0 left-0 flex flex-col w-60 rounded-lg shadow-lg bg-white border pb-4 z-50">
      <DirectionPickerTitle />
      <ArrowSelectionList selectedDirection={selectedDirection} updateDirection={updateDirection} />
    </div>
  );
}

interface ArrowSelectionListProps {
  selectedDirection: ArrowDirection;
  updateDirection: (direction: ArrowDirection) => void;
}

function ArrowSelectionList({ selectedDirection, updateDirection }: ArrowSelectionListProps) {
  // Use Object.keys to get enum names, then map to get the enum value
  return (
    <div className="" style={{ minHeight: "50px" }}>
      {Object.keys(ArrowDirection)
        .filter((key) => isNaN(Number(key))) // Filter out the numeric keys
        .map((key) => {
          const direction = ArrowDirection[key as keyof typeof ArrowDirection];
          return (
            <ArrowSelectionItem
              direction={direction}
              selected={direction === selectedDirection}
              key={direction}
              updateDirection={updateDirection}
            />
          );
        })}
    </div>
  );
}

interface ArrowSelectionItem {
  direction: ArrowDirection;
  selected: boolean;
  updateDirection: (direction: ArrowDirection) => void;
}

function ArrowSelectionItem({ direction, selected, updateDirection }: ArrowSelectionItem) {
  // The items-center class will vertically center the children,
  // and the h-8 class is an example to set a specific height for the container.
  function handleOnClick() {
    updateDirection(direction);
  }
  return (
    <div
      onClick={handleOnClick}
      className="flex items-center h-10 w-full space-x-1 hover:bg-gray-100 cursor-pointer "
    >
      <GripVertical selected={selected} />
      <ArrowIcon arrowDirection={direction} />
      <div className="pl-1 text-base text-gray-600">{getArrowDirectionString(direction)}</div>
    </div>
  );
}

function GripVertical({ selected }: { selected: boolean }) {
  return (
    <div className={`w-1 h-full ${selected ? "bg-black" : "bg-transparent"}`}>
      {/* This div represents the vertical black bar */}
    </div>
  );
}

function ArrowIcon({ arrowDirection }: { arrowDirection?: ArrowDirection }) {
  return (
    <div className="flex items-center justify-center p-1 h-8 w-8 border border-gray-300 rounded-md cursor-pointer">
      {getArrowDirection(arrowDirection, 50)}
    </div>
  );
}

function DirectionPickerTitle() {
  return (
    <span className="text-neutral-500 pl-3 py-2 text-sm font-medium">
      Select to update direction
    </span>
  );
}

function getArrowDirection(
  arrowDirection?: ArrowDirection,
  size: number = 30,
  width: string = "w-10",
  stroke: number = 1.3
) {
  if (arrowDirection === undefined) {
    return <RouteOff className={width} strokeWidth={stroke} size={size} />;
  }

  switch (arrowDirection) {
    case ArrowDirection.ToSource:
      return <MoveRight className={width} strokeWidth={stroke} size={size} />;
    case ArrowDirection.ToTarget:
      // return <MoveRight className={width} strokeWidth={stroke} size={size} />;
      return <MoveRight className={width} strokeWidth={stroke} size={size} />;

    case ArrowDirection.Both:
      return <ArrowLeftRight className={width} strokeWidth={stroke} size={size} />;
    case ArrowDirection.NoDirection:
      return <Minus className={width} strokeWidth={stroke} size={size} />;
  }
}

export function getArrowDirectionString(arrowDirection: ArrowDirection): string {
  switch (arrowDirection) {
    case ArrowDirection.ToSource:
      return "To component 2";
    case ArrowDirection.ToTarget:
      return "To component 2";
    case ArrowDirection.Both:
      return "Bi-directional";
    case ArrowDirection.NoDirection:
      return "No direction";
    default:
      throw new Error("Invalid arrow direction");
      return "none";
  }
}

export default ICDDirectionCell;
