import { useSelector } from "react-redux";
import { HorizontalDivider } from "src/components/Documents/DocumentCard/DocumentCardContextMenu";
import { BaseContextMenu, BaseContextMenuButton } from "src/components/shared/BaseContextMenu";
import { getTransformForBounds, getNodesBounds, Node, Edge } from "reactflow";
import { RootState } from "src/state/slices";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { NodeData } from "src/models/BoxDiagram/Node";
import { DocumentContainer } from "src/models/Document";
import { EdgeIdPrefix } from "src/utils/constants";
import { getArrowDirectionString } from "src/components/ICD/Columns/ICDDirectionCell";
import { edgeToDSMId } from "src/models/DSMCell";
import { titleCase } from "src/utils/helpers";

interface DocumentContextMenuProps {
  onClose?: () => void;
}
function downloadImage(dataUrl) {
  const a = document.createElement("a");

  a.setAttribute("download", "reactflow.png");
  a.setAttribute("href", dataUrl);
  a.click();
}

function exportToCSV(doc: DocumentContainer) {
  // Assuming 'edges' is an array of objects where each object is a row in your spreadsheet
  const fileName = titleCase(doc.name).replace(/\s/g, "");
  const formattedEdges = getEdgeRows(doc);
  const csv = Papa.unparse(formattedEdges);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getEdgeRows(doc: DocumentContainer) {
  return doc.edges.map((edge, index) => {
    const edgeIDSuffix = edge.id.replace(EdgeIdPrefix, "");
    const interfaceID = `${doc.id_prefix}_${edgeIDSuffix}`;
    const sourceNode = doc.nodes.find((node: Node<NodeData>) => node.id === edge.source);
    const targetNode = doc.nodes.find((node: Node<NodeData>) => node.id === edge.target);
    const dsmID = edgeToDSMId(edge, doc.nodes);
    // Format words to be capitalized and separated by a comma and space
    const connectionLabels = edge.data?.connections
      .map((connection) =>
        connection.label
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(" ")
      )
      .join(", ");
    return {
      "#": `${index}`,
      "Interface ID": interfaceID,
      "Component 1": sourceNode.data.label,
      Direction: getArrowDirectionString(edge.data.arrow_direction),
      "Component 2": targetNode.data.label,
      Description: edge.data?.icd.description,
      "Connection Type": connectionLabels,
      Responsibility: edge.data?.icd.responsibility,
      "DSM ID": dsmID,
    };
  });
}

function headRows() {
  return [
    {
      rowNumber: "Row Number",
      interfaceId: "Interface ID",
      component1: "Component 1",
      direction: "Direction",
      component2: "Component 2",
      connections: "Connection Type",
      description: "Description",
      responsibility: "Responsibility",
      dsmId: "DSM ID",
    },
  ];
}

function DocumentContextMenu({ onClose }: DocumentContextMenuProps) {
  const nodes = useSelector((state: RootState) => state.document.documentContainer.nodes);
  const edges = useSelector((state: RootState) => state.document.documentContainer.edges);
  const documentObj = useSelector((state: RootState) => state.document.documentContainer);

  function downloadPdfDiagram() {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(nodes);
    const transform = getTransformForBounds(
      nodesBounds,
      nodesBounds.width,
      nodesBounds.height,
      0.5,
      2
    );

    const element = document.querySelector(".react-flow__viewport") as HTMLElement;
    if (element) {
      toPng(element, {
        quality: 1, // Highest quality possible
        backgroundColor: "white",
        width: nodesBounds.width,
        height: nodesBounds.height,
        style: {
          width: `${nodesBounds.width}px`,
          height: `${nodesBounds.height}px`,
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        },
      })
        .then((dataUrl) => {
          downloadImage(dataUrl);
          onClose();
        })
        .catch((error) => {
          console.error("Error exporting to PNG", error);
        });
    }
  }

  function downloadPdfICD() {
    // These parameters are used to set a landscape orientation
    var doc = new jsPDF("l", "pt", "a4");

    // Set CSV Header
    doc.setFontSize(18);
    // Params are: (title, startX, startY)
    doc.text(titleCase(documentObj.name), 40, 40);
    doc.setFontSize(11);

    // Set sub text
    // var pageSize = doc.internal.pageSize;
    // var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    // var text = doc.splitTextToSize("faker.lorem.sentence(45)", pageWidth - 35, {});
    // doc.text(text, 30, 45);

    // Interpolation used for "Page X of X" footer
    var totalPagesExp = "{total_pages_count_string}";
    const headers = Object.keys(getEdgeRows(documentObj)[0]);
    const _rows = Object.values(getEdgeRows(documentObj));
    const formattedRows = _rows.map((row) => Object.values(row));

    // Construct the table
    autoTable(doc, {
      head: [headers],
      body: [...formattedRows],
      // Preset theme that overrides all other styles
      theme: "grid",
      // Y position to start the table
      startY: 55,
      // Show the title
      showHead: "firstPage",
      // Footer | Used to add the Footer onto each page
      didDrawPage: function (data) {
        const str = `Page ${doc.getNumberOfPages()} of ${totalPagesExp}`;
        doc.setFontSize(10);
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.getHeight();
        // Position the footer at the bottom left of the page with margin of 25
        doc.text(str, data.settings.margin.left, pageHeight - 25);
      },
      margin: { top: 30 },
    });

    // Converts totalPagesExp into a number for final document
    doc.putTotalPages(totalPagesExp);

    // Replace the spaces in the title with underscores
    doc.save(titleCase(documentObj.name).replace(/\s/g, ""));
    onClose();
  }

  function ICDToCSV() {
    exportToCSV(documentObj);
    onClose();
  }

  const styles = {
    top: 50,
  };

  return (
    <BaseContextMenu onClose={onClose} styles={styles}>
      <p className="mb-2 ml-2 text-lg">
        <small>Select your option</small>
      </p>
      <HorizontalDivider />
      <BaseContextMenuButton
        title={"Download Diagram PNG"}
        onClick={downloadPdfDiagram}
        showChevron
      />
      <BaseContextMenuButton title={"Download ICD PNG"} onClick={downloadPdfICD} showChevron />
      <BaseContextMenuButton title={"Download ICD CSV"} onClick={ICDToCSV} showChevron />
    </BaseContextMenu>
  );
}

export default DocumentContextMenu;
